import React from 'react';
import BgImg from '../../assets/img/subscribe_bg.jpg';

function Cta() {
    return (
        <section className="cta-wrapper">
            <div className="container">
                <div
                    className="cta-content bg-cover"
                    style={{
                        backgroundImage: `url(${BgImg})`,
                    }}
                >
                    <div className="row align-items-center">
                        <h1 className="cta-heading">Get an detailed quotation for your equipments</h1>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Cta;
