import stage1  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Rubber Two-Roll Mill Machine.png';
import stage2  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Rubber Strip Cutting Machine.jpg';
import stage3  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Hydraulic Vulcanizing Hot Press Machine.png';
import stage4  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Punching Machine.jpg';
import stage5  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Antistatic Washing and Drying Machine.png';

import prod1  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/rubber.png';
import prod2  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Semi-finished Rubber Stoppers.png';
import prod3  from '../../../assets/img/ProductionProcessImages/Rubber Stoppers/Medical Rubber Stoppers.png';


const hotPressProcessImages = {
    stages: {
        "Two-Roll Mill Machine": [stage1],
        "Rubber Strip Cutting Machine": [stage2],
        "Hydraulic Vulcanizing Hot Press Machine": [stage3],
        "Punching Machine": [stage4],
        "Antistatic Washing and Drying Machine": [stage5],
    },
  
    products: {
        "Two-Roll Mill Machine": [prod1],
        "Hydraulic Vulcanizing Hot Press Machine": [prod2],
        "Antistatic Washing and Drying Machine": [prod3],
    }
  };
  
  export default hotPressProcessImages;