import img1  from '../../../assets/img/machines/Silicone Strip Cutting Machine/Silicone CNC Strip Cutting Machine.png';

import SiliconeStripCuttingMachineSpec from '../../../components/MachineSpec/SiliconeStripCuttingMachineSpec';

const siliconeCuttingData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Silicone Strip Cutting Machine' },
    ],
    specTable: <SiliconeStripCuttingMachineSpec />
};

export default siliconeCuttingData;
