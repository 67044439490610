import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CategoryBtn from '../ProjectFilter/CategoryBtn';
import SingleProcess from './SingleProcess';

import rubberProductionProcess from './en/HotPressProductionProcess.json';
import hotPressProcessImages from './images/HotPressProcessImages';

import compressionMoldingProcess from './en/CompressionMoldingProcess.json';
import compressionMoldingProcessImages from './images/CompressionMoldingProcessImages';

import verticalRubberInjectionMoldingProcess from './en/VerticalRubberInjectionMoldingProcess.json';
import verticalRubberInjectionMoldingProcessImages from './images/VerticalRubberInjectionMoldingProcessImages';

const processData = [
  {
    id: uuidv4(),
    process: rubberProductionProcess,
    images: hotPressProcessImages,
    category: ["hotPress"],
  },

  {
    id: uuidv4(),
    process: compressionMoldingProcess,
    images: compressionMoldingProcessImages,
    category: ["compression"],
  },

  {
    id: uuidv4(),
    process: verticalRubberInjectionMoldingProcess,
    images: verticalRubberInjectionMoldingProcessImages,
    category: ["injection"],
  },
]

const ProductionProcess = () => {
  const [category, setCategory] = useState("hotPress");
  const [filteredItem, setFilteredItem] = useState([]);

  useEffect(() => {
      setFilteredItem(processData.filter((data) => data.category.includes(category)));
  }, [category]);

  return (
      <section className="production-process-section section-padding">
        <div className="container">
          <div className="row col-12 col-lg-12">
            <div className="section-title text-center">
              <p>Manufacturing Process/Production Line Examples for Rubber & Silicone Production</p>
              <h1>Complete Solutions</h1>
            </div>
          </div>

          <div className="row mb-20">
            <div className="col-12 col-lg-12 text-center">
              <div className="process-cat-filter">
                <CategoryBtn
                    className={category === 'hotPress' ? 'btn-active-process' : null}
                    name="hotPress"
                    label="Hot Press"
                    handleSetCategory={setCategory}
                />
                <CategoryBtn
                    className={category === 'compression' ? 'btn-active-process' : null}
                    name="compression"
                    label="Compression Molding"
                    handleSetCategory={setCategory}
                />
                <CategoryBtn
                    className={category === 'injection' ? 'btn-active-process' : null}
                    name="injection"
                    label="Injection Molding"
                    handleSetCategory={setCategory}
                />
              </div>
            </div>
          </div>
      
          {filteredItem.map((item) =>
            <div key={item.id}>
              <SingleProcess process={item.process} images={item.images}/>
            </div>
          )}
        </div>
      </section>
    );
  };
  
  export default ProductionProcess;
  
  