import React from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import '../../assets/css/singleLineImageList.css';

import certificationData from './certificationData';

function Certifications() {
    return (
        <section className="our-approch-wrapper section-padding">
            <div className="container">
                <div className="row mb-30">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                            <p>Meeting Global Excellence Standards</p>
                            <h1>Our Certifications</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="single-line-image-list-root">
                        <ImageList className="single-line-cert-list-wrapper" cols={4} rowHeight={350}>
                        {certificationData.map((data) => (
                            <ImageListItem className="single-line-cert-list" key={data.thumb}>
                            <div className="single-line-cert-list-img-container">
                                <img src={data.thumb} className="single-line-cert-list-img" alt={data.heading} loading="lazy"/>
                            </div>
                            </ImageListItem>
                        ))}
                        </ImageList>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Certifications;
