import img1 from '../../../assets/img/machines/hf-hot-press/hf-hot-press-2.png';
import img2 from '../../../assets/img/machines/hf-hot-press/hf-hot-press-1.png';
import img3 from '../../../assets/img/machines/hf-hot-press/hf-hot-press-3.png';

import prod1 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf1.jpg';
import prod2 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf2.jpg';
import prod3 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf3.jpg';
import prod4 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf4.jpg';
import prod5 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf5.jpg';
import prod6 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf6.jpg';
import prod7 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf7.jpg';
import prod8 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf8.jpg';
import prod9 from '../../../assets/img/machines/hf-hot-press/HF_app_products/hf9.jpg';

import HFSpec from '../../../components/MachineSpec/HFSpec';

const hfData = {
    thumbImage: '../../assets/img/machines/hf-hot-press/hf-hot-press-2.png',
    images: [
        { img: img1 },
        { img: img2 },
        { img: img3 },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
        { img: prod6 },
        { img: prod7 },
        { img: prod8 },
        { img: prod9 },
    ],
    specTable: <HFSpec />,
  };
  
  export default hfData;
  