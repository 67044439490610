import React from 'react';

const HIFLSiliconeSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>200HI-FL</th>
                            <th>250HI-FL</th>
                            <th>300HI-FL</th>
                            <th>400HI-FL</th>
                            <th>500HI-FL</th>
                            <th>550HI-FL</th>
                            <th>600HI-FL</th>
                            <th>800HI-FL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force(Ton)</td>
                            <td>200</td>
                            <td>250</td>
                            <td>300</td>
                            <td>400</td>
                            <td>500</td>
                            <td>550</td>
                            <td>600</td>
                            <td>600</td>
                        </tr>
                        <tr>
                            <td>Injection Volume(cc)</td>
                            <td>2000</td>
                            <td>2500</td>
                            <td>3000</td>
                            <td>4000</td>
                            <td>16000</td>
                            <td>10000 (16000)</td>
                            <td>6000</td>
                            <td>8000</td>
                        </tr>
                        <tr>
                            <td>Injection Pressure(kgf/cm2)</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                        </tr>
                        <tr>
                            <td>Platen Size(mm)</td>
                            <td>550*560</td>
                            <td>600*700</td>
                            <td>700*700</td>
                            <td>700*700</td>
                            <td>900*1100</td>
                            <td>900*1100</td>
                            <td>850*850</td>
                            <td>1000*1000</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter(mm)</td>
                            <td>Φ 360</td>
                            <td>Φ 400</td>
                            <td>Φ 450</td>
                            <td>Φ 500</td>
                            <td>Φ 560</td>
                            <td>Customized</td>
                            <td>Customized</td>
                            <td>Customized</td>
                        </tr>
                        <tr>
                            <td>Min. Mold Thickness(mm)</td>
                            <td>100</td>
                            <td>100</td>
                            <td>200</td>
                            <td>200</td>
                            <td>110</td>
                            <td>260</td>
                            <td>200</td>
                            <td>250</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke(mm)</td>
                            <td>500</td>
                            <td>500</td>
                            <td>400 (600)</td>
                            <td>400</td>
                            <td>700</td>
                            <td>700</td>
                            <td>500</td>
                            <td>500</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance(mm)</td>
                            <td>600</td>
                            <td>600</td>
                            <td>600</td>
                            <td>600</td>
                            <td>800</td>
                            <td>960</td>
                            <td>700</td>
                            <td>750</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance(mm)</td>
                            <td>640*310</td>
                            <td>705*400</td>
                            <td>820*395</td>
                            <td>820*395</td>
                            <td>1105*475</td>
                            <td>850*640</td>
                            <td>970*500</td>
                            <td>1240*590</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="8">2RT-3RT-4RT Overhead Suspension Mold-Bottom Suspension Mold</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure(MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power(KW)</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                            <td>15</td>
                            <td>15</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>Heating Power(KW)</td>
                            <td>15.3</td>
                            <td>18</td>
                            <td>24</td>
                            <td>24</td>
                            <td>26.4</td>
                            <td>45</td>
                            <td>29.75</td>
                            <td>35</td>
                        </tr>
                        <tr>
                            <td>Total Power(KW)</td>
                            <td>26.3</td>
                            <td>39</td>
                            <td>35</td>
                            <td>35</td>
                            <td>37.4</td>
                            <td>60</td>
                            <td>44.75</td>
                            <td>50</td>
                        </tr>
                        <tr>
                            <td>Gross Weight(Ton)</td>
                            <td>7.3</td>
                            <td>8</td>
                            <td>9.5</td>
                            <td>10.5</td>
                            <td>30</td>
                            <td>25</td>
                            <td>14.5</td>
                            <td>18.5</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H(mm)</td>
                            <td>3400*2400 *2600</td>
                            <td>3400*2500 *2650</td>
                            <td>3500*2600 *2700</td>
                            <td>3500*2600 *2700</td>
                            <td>4810*3800 *3200</td>
                            <td>5200*2600 *3200</td>
                            <td>3800*2800 *2800</td>
                            <td>4000*3050 *2900</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HIFLSiliconeSpec;