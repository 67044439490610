import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJx2Y-wbWzAzQRzrxh2pgtSdc&key=AIzaSyB2eSEjxkfvo85-jtmDodAIwLaTYLCPwsc"
                style={{
                    border: '0',
                    width: '100%',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
        </div>
    );
}

export default Map;
