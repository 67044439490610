import img1  from '../../../assets/img/machines/Rubber Two-Roll Mill Machine/Rubber Two-Roll Mill Machine.png';

import RubberTwoRollMillSpec from '../../../components/MachineSpec/RubberTwoRollMillSpec';

const millData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Rubber Two-Roll Mill Machine' },
    ],
    specTable: <RubberTwoRollMillSpec />,
  };
  
  export default millData;
