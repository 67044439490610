import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import aboutImg from '../../assets/img/about_us.png';
import btnImg from '../../assets/img/factory.png';

function AboutFeatured() {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="Y4cFyWIxYj4"
                onClose={() => setOpen(false)}
            />
            <section className="about-featured-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-12 text-center">
                            <div
                                className="about-promo bg-cover"
                                style={{ backgroundImage: `url(${aboutImg})` }}
                            >
                                <div
                                    className="skill-popup-video d-flex justify-content-center align-items-center bg-cover"
                                    style={{ backgroundImage: `url(${btnImg})` }}
                                >
                                    <div className="video-play-btn">
                                        <button
                                            type="button"
                                            className="modal-btn"
                                            onClick={() => setOpen(true)}
                                        >
                                            <FaPlay />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-12">
                            <div className="block-contents ml-lg-5">
                                <span>Engineering Excellence</span>
                                <h1>
                                <span style={{ color: '#1a98ff' }}>Juchuan</span>
                                , Your Trusted Machinery Partner.
                                </h1>
                                <h4>
                                We a leading manufacturer of silicone and rubber processing equipment. Our <strong>20,000-square-meter facility</strong> is equipped with more than 20 sets of advanced machinery. We specialize in R&D, manufacturing, sales, and service of high-performance rubber and silicone equipment.
                                {/* <br/>
                                <br/>
                                Our product line includes <strong>precision silicone and rubber injection molding machines, vacuum vulcanizing systems, automatic mold-opening flat vulcanizing machines, and custom rubber injection molds</strong>. Our team of senior engineers collaborates with German industry experts, developing patented technologies that drive innovation in silicone and rubber processing. Trust Juchuan for cutting-edge solutions in precision molding and injection technology. */}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutFeatured;
