import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import '../../assets/css/singleLineImageList.css';


export default function singleLineImageList({itemData}) {
  return (
    <div style={{ paddingTop: '30px' }}>
      <h2>Application Products</h2>
      <div className="single-line-image-list-root">
        <ImageList className="single-line-image-list-wrapper" cols={5} rowHeight={200}>
          {itemData.map((item) => (
            <ImageListItem className="single-line-image-list" key={item.img}>
              <div className="single-line-image-list-img-container">
                <img src={item.img} className="single-line-image-list-img" alt={item.alt} loading="lazy"/>
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div>
  );
}
