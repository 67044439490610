import React from 'react';

const HFSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>100HF</th>
                            <th>200HF</th>
                            <th>250HF</th>
                            <th>300HF</th>
                            <th>350HF</th>
                            <th>400HF</th>
                            <th>500HF</th>
                            <th>1200HF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force (Ton)</td>
                            <td>100</td>
                            <td>200</td>
                            <td>250</td>
                            <td>300</td>
                            <td>350</td>
                            <td>400</td>
                            <td>500</td>
                            <td>1200</td>
                        </tr>
                        <tr>
                            <td>Platen Size (mm)</td>
                            <td>400*460</td>
                            <td>550*560</td>
                            <td>650*600</td>
                            <td>700*800</td>
                            <td>750*750</td>
                            <td>850*850</td>
                            <td>1000*1000</td>
                            <td>1600*2200</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter (mm)</td>
                            <td>Φ 250</td>
                            <td>Φ 360</td>
                            <td>Φ 400</td>
                            <td>Φ 450</td>
                            <td>Φ 480</td>
                            <td>Φ 500</td>
                            <td>Φ 560</td>
                            <td>Customized</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke (mm)</td>
                            <td>200</td>
                            <td>250</td>
                            <td>250</td>
                            <td>300</td>
                            <td>300</td>
                            <td>300</td>
                            <td>350</td>
                            <td>350</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance (mm)</td>
                            <td>200 ~ 300</td>
                            <td>250 ~ 350</td>
                            <td>250 ~ 350</td>
                            <td>300 ~ 400</td>
                            <td>300 ~ 400</td>
                            <td>300 ~ 400</td>
                            <td>350 ~ 450</td>
                            <td>350 ~ 550</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance (mm)</td>
                            <td>490*230</td>
                            <td>650*320</td>
                            <td>745*310</td>
                            <td>785*380</td>
                            <td>/</td>
                            <td>940*450</td>
                            <td>1105*475</td>
                            <td>Six Tie Bars</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="8">2RT-3RT-4RT-Track-Suspension</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure (MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power (KW)</td>
                            <td>5.5*2</td>
                            <td>7.5*2</td>
                            <td>7.5*2</td>
                            <td>11*2</td>
                            <td>11*2</td>
                            <td>11</td>
                            <td>11</td>
                            <td>18.5</td>
                        </tr>
                        <tr>
                            <td>Heating Power (KW)</td>
                            <td>7.2*2</td>
                            <td>13.5*2</td>
                            <td>15.3*2</td>
                            <td>24*2</td>
                            <td>24*2</td>
                            <td>24*2</td>
                            <td>26.4*2</td>
                            <td>36</td>
                        </tr>
                        <tr>
                            <td>Total Power (KW)</td>
                            <td>25.4</td>
                            <td>42</td>
                            <td>45.6</td>
                            <td>63</td>
                            <td>70</td>
                            <td>74.8</td>
                            <td>47</td>
                            <td>144.5</td>
                        </tr>
                        <tr>
                            <td>Gross Weight (Ton)</td>
                            <td>5</td>
                            <td>7.8</td>
                            <td>11</td>
                            <td>15</td>
                            <td>/</td>
                            <td>17</td>
                            <td>13.5</td>
                            <td>28.5</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H (mm)</td>
                            <td>2500*2080*1950</td>
                            <td>2930*2350*2050</td>
                            <td>3190*2780*2230</td>
                            <td>3360*2970*2250</td>
                            <td>Customized</td>
                            <td>3660*3050*2350</td>
                            <td>1460*3600*2380</td>
                            <td>2350*5400*2950</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HFSpec;
