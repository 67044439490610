import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import '../../assets/css/detailsCarousel.css';

SwiperCore.use([Navigation, Pagination]);

function DetailsCarousel({ images, title }) {
    return (
        <div>
            <Swiper navigation pagination className="product-gallery">
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <div className="single-product-photo">
                            <div className="details-carousel-img-container">
                                <img src={image.img} alt={title} className="details-carousel-img" loading="lazy"/>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}

export default DetailsCarousel;
