import React from 'react';

const TPPressSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>200TP</th>
                            <th>250TP</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force(Ton)</td>
                            <td>200</td>
                            <td>250</td>
                        </tr>
                        <tr>
                            <td>Max. Transfer Press Force(Ton)</td>
                            <td>62.8</td>
                            <td>98</td>
                        </tr>
                        <tr>
                            <td>Platen Size(mm)</td>
                            <td>550*560</td>
                            <td>650*600</td>
                        </tr>
                        <tr>
                            <td>Transfer Press Plunger Diameter(mm)</td>
                            <td>Φ 90</td>
                            <td>Φ 90</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter(mm)</td>
                            <td>360</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke(mm)</td>
                            <td>270</td>
                            <td>270</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance(mm)</td>
                            <td>250~350</td>
                            <td>250~350</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance(mm)</td>
                            <td>650*320</td>
                            <td>745*310</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="2">2RT-3RT-4RT-Track-Suspension</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure(MPa)</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power(KW)</td>
                            <td>7.5*2</td>
                            <td>7.5*2</td>
                        </tr>
                        <tr>
                            <td>Heating Power(KW)</td>
                            <td>13.5*2</td>
                            <td>15.3*2</td>
                        </tr>
                        <tr>
                            <td>Total Power(KW)</td>
                            <td>42</td>
                            <td>45.6</td>
                        </tr>
                        <tr>
                            <td>Gross Weight(Ton)</td>
                            <td>7.8</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H(mm)</td>
                            <td>2930*2350*3450</td>
                            <td>3190*2780*3500</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TPPressSpec;