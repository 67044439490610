import React from 'react';
import detailsCardData from './detailsCardData';
import AdvantageDetailsCard from './AdvantageDetailsCard';

export default function App() {
    return (
        <section className="timeline-wrapper section-padding section-bg">
            <div className="container">
                <div className="row mb-50">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                            <span>advantage</span>
                            <p>Our Advantages</p>
                            <h1>Why Juchuan?</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    {detailsCardData.map((data) => (
                        <AdvantageDetailsCard
                            key={data.id}
                            icon={data.icon}
                            heading={data.heading}
                            num={data.num}
                            text={data.text}
                        />
                    ))}
                </div>
            </div>

        </section>
    );
}
