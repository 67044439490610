import { AiOutlineMail } from 'react-icons/ai';
import { IoMdText } from "react-icons/io";
import {
    FaWhatsapp,
} from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const WidgetGetinTouchData = [
    {
        id: uuidv4(),
        icon: <FaWhatsapp />,
        heading: 'WhatsApp',
        text: 'Juchuan Machinery',
        refLink: 'https://wa.me/17373281987'
    },

    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email',
        text: 'info@juchuanmachines.com',
        refLink: 'mailto:info@juchuanmachines.com'
    },

    {
        id: uuidv4(),
        icon: <IoMdText />,
        heading: 'Text',
        text: '(+1) 719-JUCHUAN',
        refLink: 'sms:+17195824826'
    },
];

export default WidgetGetinTouchData;
