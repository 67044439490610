import img1  from '../../../assets/img/machines/Rubber Deflashing Machine/Rubber Deflashing Machine.jpg';

import prod1  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/Colorful Rubber O-Ring Display.jpg';
import prod2  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/Oval Rubber Gasket.png';
import prod3  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/Perforated Rubber Strip.png';
import prod4  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/Red Rubber Circular Component.png';
import prod5  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/Rubber O-Ring Assortment.jpg';
import prod6  from '../../../assets/img/machines/Rubber Deflashing Machine/app_products/White Rubber Circular Component.png';

const deflashingData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Rubber Deflashing Machine' },
    ],
    applicationImages: [
        { img: prod1, alt: 'Colorful arrangement of rubber O-rings in black, red, and green' },
        { img: prod2, alt: 'Large black rubber oval gasket or seal' },
        { img: prod3, alt: 'Black rubber strip with circular cutouts' },
        { img: prod4, alt: 'Red circular rubber component with four spokes' },
        { img: prod5, alt: 'Assorted black and green rubber O-rings of various sizes' },
        { img: prod6, alt: 'White circular rubber component with four spokes' },
    ],
};

export default deflashingData;