import React from 'react';

const HIFLRubberSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>200HI-FL</th>
                            <th>300HI-FL</th>
                            <th>400HI-FL</th>
                            <th>500HI-FL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force (Ton)</td>
                            <td>200</td>
                            <td>300</td>
                            <td>400</td>
                            <td>500</td>
                        </tr>
                        <tr>
                            <td>Injection Volume (cc)</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>3000</td>
                        </tr>
                        <tr>
                            <td>Injection Pressure (kgf/c2)</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                        </tr>
                        <tr>
                            <td>Platen Size (mm)</td>
                            <td>550*560</td>
                            <td>700*700</td>
                            <td>700*700</td>
                            <td>900*900</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter (mm)</td>
                            <td>Φ 360</td>
                            <td>Φ 450</td>
                            <td>Φ 500</td>
                            <td>Φ 560</td>
                        </tr>
                        <tr>
                            <td>Min. Mold Thickness (mm)</td>
                            <td>100</td>
                            <td>50</td>
                            <td>100</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke (mm)</td>
                            <td>400</td>
                            <td>400 (600)</td>
                            <td>400</td>
                            <td>500</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance (mm)</td>
                            <td>500 ~ 600</td>
                            <td>600</td>
                            <td>600</td>
                            <td>700</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance (mm)</td>
                            <td>640*310</td>
                            <td>820*395</td>
                            <td>820*395</td>
                            <td>1105*475</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="4">2RT-3RT-4RT Overhead Suspension Mold-Bottom Suspension Mold</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure (MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power (KW)</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Heating Power (KW)</td>
                            <td>15.3</td>
                            <td>24</td>
                            <td>24</td>
                            <td>24.2</td>
                        </tr>
                        <tr>
                            <td>Total Power (KW)</td>
                            <td>26.3</td>
                            <td>35</td>
                            <td>35</td>
                            <td>35.2</td>
                        </tr>
                        <tr>
                            <td>Gross Weight (Ton)</td>
                            <td>7.8</td>
                            <td>9.5</td>
                            <td>10.5</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H (mm)</td>
                            <td>3400*2400*2600</td>
                            <td>3500*2600*2700</td>
                            <td>3500*2600*2700</td>
                            <td>5100*3400*3000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HIFLRubberSpec;