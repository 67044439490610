import { v4 as uuidv4 } from 'uuid';
import { SlBadge } from "react-icons/sl";
import { BiBuildings } from "react-icons/bi";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";
import { GiArchiveResearch, GiWorld } from "react-icons/gi";
import { GrCertificate } from "react-icons/gr";
import { Md360 } from "react-icons/md";
import { GoMilestone } from "react-icons/go";

const detailsCardData = [
    {
        id: uuidv4(),
        icon: <SlBadge />,
        num: '1997',
        heading: 'Molding Pioneers',
        text: 'Decades of expertise in advanced molding machinery solutions',
    },
    {
        id: uuidv4(),
        icon: <BiBuildings />,
        num: '20000m²',
        heading: 'Production Powerhouse',
        text: 'State-of-the-art silicone and rubber equipment manufacturing facility',
    },
    {
        id: uuidv4(),
        icon: <MdOutlinePrecisionManufacturing />,
        num: '20+',
        heading: 'Machine Diversity',
        text: 'Diverse range of specialized rubber and silicone molding machines',
    },
    {
        id: uuidv4(),
        icon: <GiArchiveResearch />,
        num: 'R&D',
        heading: 'Tech Vanguard',
        text: 'Cutting-edge research with German collaborations and patented technologies',
    },
    {
        id: uuidv4(),
        icon: <GiWorld />,
        num: '30+',
        heading: 'Worldwide Presence',
        text: 'Exporting high-quality molding equipment to countries worldwide',
    },
    {
        id: uuidv4(),
        icon: <GrCertificate />,
        num: 'ISO',
        heading: 'Excellence Certified',
        text: 'Comprehensive management system for superior molding machine production',
    },
    {
        id: uuidv4(),
        icon: <Md360 />,
        num: '360°',
        heading: 'Total Care',
        text: 'Full-spectrum technical assistance for all molding equipment needs',
    },
    {
        id: uuidv4(),
        icon: <GoMilestone />,
        num: 'One-Stop',
        heading: 'Complete Solutions',
        text: 'End-to-end solutions from design to after-sales for molding machinery',
    },
];

export default detailsCardData;