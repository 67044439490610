import React from 'react';
import bgImg from '../../assets/img/global-clients.png';

function GlobalPresence() {
    return (
        <section className="global-presence-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="content-block">
                        <p>Exporting high-quality molding equipment to countries worldwide.</p>
                        <h1>Our Worldwide Presence</h1>
                        <div className="bg-img">
                            <img src={bgImg} alt="Juchuan Machine Customer Worldwide" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default GlobalPresence;
