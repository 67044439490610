import React, { useEffect, useState } from 'react';
import CategoryBtn from '../ProjectFilter/CategoryBtn';
import productsTwoData from './productsTwoData';
import ProductsTwoCard from './ProductsTwoCard';
import CustomForm from './CustomForm';
import CustomGallery from './CustomGallery';

function ProductsGrid({ machineCategory }) {
    const [category, setCategory] = useState(machineCategory);
    const [filteredItem, setFilteredItem] = useState([]);

    useEffect(() => {
        if (category === 'all') {
            setFilteredItem(productsTwoData);
        } else {
            setFilteredItem(productsTwoData.filter((data) => data.category.includes(category)));
        }
    }, [category]);

    useEffect(() => {
        setCategory(machineCategory);
    }, [machineCategory]);

    return (
        <section className="our-service-wrapper product-grid-section-padding mtm-30">
            <div className="row mb-20">
                <div className="col-12 col-lg-12 text-center">
                    <div className="portfolio-cat-filter">
                        <CategoryBtn
                            className={category === 'rubber' ? 'btn-active' : null}
                            name="rubber"
                            label="Rubber Molding"
                            handleSetCategory={setCategory}
                        />
                        <CategoryBtn
                            className={category === 'silicone' ? 'btn-active' : null}
                            name="silicone"
                            label="Silicone Molding"
                            handleSetCategory={setCategory}
                        />
                        <CategoryBtn
                            className={category === 'brake' ? 'btn-active' : null}
                            name="brake"
                            label="Brake Manufacturing"
                            handleSetCategory={setCategory}
                        />
                        <CategoryBtn
                            className={category === 'auxiliary' ? 'btn-active' : null}
                            name="auxiliary"
                            label="Auxiliary Machines"
                            handleSetCategory={setCategory}
                        />
                        <CategoryBtn
                            className={category === 'all' ? 'btn-active' : null}
                            name="all"
                            label="View All"
                            handleSetCategory={setCategory}
                        />
                        <CategoryBtn
                            className={category === 'custom' ? 'btn-active' : null}
                            name="custom"
                            label="Customizations"
                            handleSetCategory={setCategory}
                        />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    {filteredItem.map((item) =>
                        (<ProductsTwoCard
                            key={item.id}
                            thumbnail={item.thumbnail}
                            heading={item.heading}
                            link={item.link}
                            category={item.category}
                        />)
                    )}
                </div>
                {category === 'custom' && (
                    <div className="row mt-30">
                        <CustomForm />
                        <CustomGallery />
                    </div>
                )}
            </div>
        </section>
    );
}

export default ProductsGrid;
