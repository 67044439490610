import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomGalleryData from './CustomGalleryData';

export default function CustomGallery() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));

  const getCols = () => {
    if (isXs) return 1;
    if (isSm) return 2;
    return 3;
  };

  const getCardThumbHeight = () => {
    if (isXs) return '200px';
    if (isSm) return '300px';
    if (isMd) return '350px';
    return '400px';
  };

  return (
    <section className="contact-form-wrapper pt-0" style={{ marginTop: '50px' }}>
    {/* <div className="row" style={{marginTop: "68px"}}> */}
      <div className="col-12 text-center">
        <span>Custom-Engineered Solutions for Our Clients</span>
        <h2>Customization Portfolio</h2>
      </div>
      <ImageList
        sx={{
          width: '100%',
          height: '50vw',
          gap: 16,
          marginTop: 5
        }}
        cols={getCols()}
      >
        {CustomGalleryData.map((item) => (
          <ImageListItem key={item.thumbnail}>
            <div className="card-thumb" style={{
              height: getCardThumbHeight(),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden'
            }}>
              <img
                srcSet={`${item.thumbnail}?w=248&fit=contain&auto=format&dpr=2 2x`}
                src={`${item.thumbnail}?w=248&fit=contain&auto=format`}
                alt={item.heading}
                loading="lazy"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            </div>
            <ImageListItemBar
              title={item.heading}
              sx={{
                '& .MuiImageListItemBar-title': {
                  textAlign: 'center',
                  width: '100%',
                },
              }}
              actionIcon={
                <IconButton
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.heading}`}
                >
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
    {/* </div> */}
    </section>
  );
}