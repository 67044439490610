import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {
    FaClock,
    FaWhatsapp,
    FaMap,
    FaPlus,
    FaYoutube,
    FaTiktok
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';

function OffsetMenu({ className, handleOffset }) {
    return (
        <div className={className}>
            <span id="offset-menu-close-btn">
                <FaPlus onClick={handleOffset} />
            </span>
            <div className="offset-menu-wrapper text-white">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className="offset-menu-section">
                    <h3>About Us</h3>
                    <p>
                        We specializes in manufacturing rubber and silicone equipment.
                        Established in 1997, we boast a 30-acre facility with over 20 processing equipment sets.
                        Our product range includes precision silicone molding machines, rubber injection molding machines,
                        vacuum compression molding machines, and custom rubber molds. With expertise in research,
                        manufacturing, and service, Juchuan delivers innovative, high-quality solutions.
                        Trust our 20+ years of industry experience for your rubber and silicone equipment needs.
                    </p>
                    <Link to="/contact" className="theme-btn mt-30">
                        Contact
                    </Link>
                </div>
                <div className="offset-menu-section">
                    <h3>Contact Info</h3>
                    <ul>
                        <li>
                            <span>
                                <FaMap />
                            </span>
                            16 Liyu Street,
                            Nansha District, Guangzhou, Guangdong Province
                            China 511473
                        </li>
                        <li>
                            <span>
                                <a href="https://wa.me/17373281987">
                                    <FaWhatsapp />
                                </a>
                            </span>
                            (+1) 719-JUCHUAN
                        </li>
                        <li>
                            <span>
                                <a href="mailto:info@juchuanmachines.com">
                                    <AiOutlineMail className="icon" />
                                </a>
                            </span>
                            info@juchuanmachines.com
                        </li>
                        <li>
                            <span>
                                <FaClock />
                            </span>
                            Mon-Sat: 08.00 to 18.00 (GMT+8)
                        </li>
                    </ul>
                </div>
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="https://wa.me/17373281987">
                            <FaWhatsapp />
                        </a>
                        <a href="https://www.tiktok.com/@juchuanmachines">
                            <FaTiktok />
                        </a>
                        <a href="https://www.youtube.com/@JuchuanManchinery">
                            <FaYoutube />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OffsetMenu;
