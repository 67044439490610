import img1 from '../../../assets/img/machines/vf-vacuum-molding/vf-vacuum-molding-front.png';
import img2 from '../../../assets/img/machines/vf-vacuum-molding/vf-vacuum-molding-side.png';

import prod1 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf1.jpg';
import prod2 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf2.jpg';
import prod3 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf3.jpg';
import prod4 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf4.jpg';
import prod5 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf5.jpg';
import prod6 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf6.jpg';
import prod7 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf7.jpg';
import prod8 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf8.jpg';
import prod9 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf9.jpg';
import prod10 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf10.jpg';
import prod11 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf11.jpg';
import prod12 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf12.jpg';
import prod13 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf13.jpg';
import prod14 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf14.jpg';
import prod15 from '../../../assets/img/machines/vf-vacuum-molding/VF_app_products/vf15.jpg';

import VFSpec from '../../../components/MachineSpec/VFSpec';

const vfData = {
    thumbImage: '../assets/img/machines/vf-vacuum-molding/vf-vacuum-molding-front.png',
    images: [
        { img: img1 },
        { img: img2 },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
        { img: prod6 },
        { img: prod7 },
        { img: prod8 },
        { img: prod9 },
        { img: prod10 },
        { img: prod11 },
        { img: prod12 },
        { img: prod13 },
        { img: prod14 },
        { img: prod15 },
    ],
    specTable: <VFSpec />
  };
  
  export default vfData;
  