import React from 'react';

function WidgetGetinTouch({ heading, text, icon, refLink }) {
    return (
        <>
            <div className="single-contact-info">
                {refLink ? (
                    <div className="icon">
                        <a href={refLink}>
                            {icon}
                        </a>
                    </div>
                ) : (
                    <div className="icon">{icon}</div>
                )}
                <div className="contact-info">
                    <span>{heading}</span>
                    <p>{text}</p>
                </div>
            </div>
        </>
    );
}

export default WidgetGetinTouch;
