import img1  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/Air Separation Machine with Automatic Feeder.png';

import prod1  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Air-Separation-Disc-For-Rubber-Machine.png';
import prod2  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Air-Separation-Filters-For-Rubber-Processing-Black.png';
import prod3  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Air-Separation-Filters-For-Rubber-Processing.png';
import prod4  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Air-Separation-Machine-Clips.png';
import prod5  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Rubber-Air-Separation-Ring.png';
import prod6  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Rubber-Machine-Perm-Rods-Multicolor.png';
import prod7  from '../../../assets/img/machines/Air Separation Machine with Automatic Feeder/app_products/Rubber-Machine-Perm-Rods.png';

const feederData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Air Separation Machine with Automatic Feeder' },
    ],
    applicationImages: [
        { img: prod1, alt: 'Precision air separation disc for rubber machine, featuring evenly spaced notches for efficient material sorting' },
        { img: prod2, alt: 'Set of four black air separation filters with mesh screens for rubber processing machines, ensuring effective material filtration' },
        { img: prod3, alt: 'Set of four air separation filters for rubber processing machines, designed for efficient material filtration' },
        { img: prod4, alt: 'Durable clips for air separation machine, ensuring secure attachment in rubber processing equipment' },
        { img: prod5, alt: 'Circular air separation ring for rubber machine, with precision-engineered slots for effective material sorting' },
        { img: prod6, alt: 'Multicolor perm rods for rubber machine air separation, featuring segmented design for efficient material processing' },
        { img: prod7, alt: 'Red perm rods for rubber machine air separation, designed for optimal material handling and processing' },
    ]
};

export default feederData;
