import img1  from '../../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/Hot Air Rotary Drum Dryer.png';

import prod1 from '../../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/app_products/rubber-o-rings-seals-machine-output.jpg';
import prod2 from '../../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/app_products/rubber-parts-machine-products.jpg';
import prod3 from '../../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/app_products/rubber-shoe-soles-machine-tread.png';
import prod4 from '../../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/app_products/silicone-gloves-rubber-machine-products.png';

const hotDryData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Stainless Steel Hot Air Rotary Drum Dryer ' },
    ],
    applicationImages: [
        { img: prod1, alt: 'Assorted rubber O-rings and seals in black and green, produced by rubber molding machines' },
        { img: prod2, alt: 'Various rubber components including caps, plugs and rings manufactured by industrial rubber machines' },
        { img: prod3, alt: 'Yellow rubber shoe soles with tread pattern, molded by rubber footwear manufacturing machines' },
        { img: prod4, alt: 'Colorful silicone cleaning gloves in pink, teal, purple and blue, made with rubber processing equipment' },
    ],
};

export default hotDryData;