import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import FlowPopup from './components/FlowPopup';
import About from './pages/About';
import Contact from './pages/Contact';
import Faq from './pages/FaqPage';
import Home1 from './pages/Home1';
import Products from './pages/Products';
import ProductsDetails from './pages/ProductsDetails';

import brakeJson from './machines/machineJson/en/brakeHP.json';
import brakeData from './machines/machineData/en/brakeHP';

import hfJson from './machines/machineJson/en/HF.json';
import hfData from './machines/machineData/en/HF';

import hiflRubberJson from './machines/machineJson/en/HIFLRubber.json';
import hiflRubberData from './machines/machineData/en/HIFLRubber';

import hiflSiliconeJson from './machines/machineJson/en/HIFLSilicone.json';
import hiflSiliconeData from './machines/machineData/en/HIFLSilicone';

import lv1Json from './machines/machineJson/en/LV1.json';
import lv1Data from './machines/machineData/en/LV1';

import lv2Json from './machines/machineJson/en/LV2.json';
import lv2Data from './machines/machineData/en/LV2';

import tpPressJson from './machines/machineJson/en/TPPress.json';
import tpPressData from './machines/machineData/en/TPPress';

import vfJson from './machines/machineJson/en/VF.json';
import vfData from './machines/machineData/en/VF';

import viaoRubberJson from './machines/machineJson/en/VIAORubber.json';
import viaoRubberData from './machines/machineData/en/VIAORubber';

import viaoSiliconeJson from './machines/machineJson/en/VIAOSilicone.json';
import viaoSiliconeData from './machines/machineData/en/VIAOSilicone';

import viflJson from './machines/machineJson/en/VIFL.json';
import viflData from './machines/machineData/en/VIFL';

import vifoJson from './machines/machineJson/en/VIFO.json';
import vifoData from './machines/machineData/en/VIFO';

import millJson from './machines/machineJson/en/TwoRollMill.json';
import millData from './machines/machineData/en/TwoRollMill';

import washJson from './machines/machineJson/en/WashingDrying.json';
import washData from './machines/machineData/en/WashingDrying';

import hotDryJson from './machines/machineJson/en/HotDry.json';
import hotDryData from './machines/machineData/en/HotDry';

import deflashingJson from './machines/machineJson/en/Deflashing.json';
import deflashingData from './machines/machineData/en/Deflashing';

import siliconeCuttingJson from './machines/machineJson/en/SiliconeCutting.json';
import siliconeCuttingData from './machines/machineData/en/SiliconeCutting';

import rubberCuttingJson from './machines/machineJson/en/RubberCutting.json';
import rubberCuttingData from './machines/machineData/en/RubberCutting';

import openMillJson from './machines/machineJson/en/OpenMill.json';
import openMillData from './machines/machineData/en/OpenMill';

import feederJson from './machines/machineJson/en/Feeder.json';
import feederData from './machines/machineData/en/Feeder';

import punchingJson from './machines/machineJson/en/punching.json';
import punchingData from './machines/machineData/en/Punching';

function App() {
  return (
    <>
      <Router>
        <ScrollToTopRoute />
        <Routes>
          <Route exact path="/about" element={<About />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/machines" element={<Products machineCategory={"all"}/>} />
          <Route exact path="/machines/silicone" element={<Products machineCategory={"silicone"}/>} />
          <Route exact path="/machines/rubber" element={<Products machineCategory={"rubber"}/>} />
          <Route exact path="/machines/brake" element={<Products machineCategory={"brake"}/>} />
          <Route exact path="/machines/customizations" element={<Products machineCategory={"custom"}/>} />
          <Route exact path="/machines/auxiliary" element={<Products machineCategory={"auxiliary"}/>} />
          
          <Route exact path="/machine/brake-pad-and-lining-hot-press-machine" element={<ProductsDetails jsonData={brakeJson} jsData={brakeData} />} />
          <Route exact path="/machine/hydraulic-hot-press-machine" element={<ProductsDetails jsonData={hfJson} jsData={hfData} />} />
          <Route exact path="/machine/horizontal-rubber-injection-molding-machine/hi-fl" element={<ProductsDetails jsonData={hiflRubberJson} jsData={hiflRubberData}/>} />
          <Route exact path="/machine/horizontal-silicone-injection-molding-machine/hi-fl" element={<ProductsDetails jsonData={hiflSiliconeJson} jsData={hiflSiliconeData}/>} />
          <Route exact path="/machine/vertical-liquid-silicone-injection-molding-machine" element={<ProductsDetails jsonData={lv1Json} jsData={lv1Data}/>} />
          <Route exact path="/machine/solid-liquid-silicone-molding-machine" element={<ProductsDetails jsonData={lv2Json} jsData={lv2Data}/>} />
          <Route exact path="/machine/vertical-transfer-press-molding-machine" element={<ProductsDetails jsonData={tpPressJson} jsData={tpPressData}/>} />
          <Route exact path="/machine/vacuum-compression-molding-machine" element={<ProductsDetails jsonData={vfJson} jsData={vfData}/>} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-ao" element={<ProductsDetails jsonData={viaoRubberJson} jsData={viaoRubberData}/>} />
          <Route exact path="/machine/vertical-solid-silicone-injection-molding-machine/vi-ao" element={<ProductsDetails jsonData={viaoSiliconeJson} jsData={viaoSiliconeData}/>} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-fl" element={<ProductsDetails jsonData={viflJson} jsData={viflData}/>} />
          <Route exact path="/machine/vertical-rubber-injection-molding-machine/vi-fo" element={<ProductsDetails jsonData={vifoJson} jsData={vifoData}/>} />
          
          <Route exact path="/machine/rubber-two-roll-mill-machine" element={<ProductsDetails jsonData={millJson} jsData={millData}/>} />
          <Route exact path="/machine/antistatic-washing-drying-machine" element={<ProductsDetails jsonData={washJson} jsData={washData}/>} />
          <Route exact path="/machine/stainless-steel-hot-air-rotary-drum-dryer" element={<ProductsDetails jsonData={hotDryJson} jsData={hotDryData}/>} />
          <Route exact path="/machine/rubber-deflashing-machine" element={<ProductsDetails jsonData={deflashingJson} jsData={deflashingData}/>} />
          <Route exact path="/machine/silicone-strip-cutting-machine" element={<ProductsDetails jsonData={siliconeCuttingJson} jsData={siliconeCuttingData}/>} />
          <Route exact path="/machine/rubber-strip-cutting-machine" element={<ProductsDetails jsonData={rubberCuttingJson} jsData={rubberCuttingData}/>} />
          <Route exact path="/machine/two-roll-open-mill-machine-for-rubber-mixing" element={<ProductsDetails jsonData={openMillJson} jsData={openMillData}/>} />
          <Route exact path="/machine/air-separation-machine-with-automatic-feeder" element={<ProductsDetails jsonData={feederJson} jsData={feederData}/>} />
          <Route exact path="/machine/punching-machine" element={<ProductsDetails jsonData={punchingJson} jsData={punchingData}/>} />

          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/" element={<Home1 />} />
        </Routes>
      </Router>
      {/* <ScrollIndicator /> */}
      <FlowPopup />
    </>
  );
}

export default App;