import stage1   from '../../../assets/img/ProductionProcessImages/Injection Molding/Rubber Two-Roll Mill Machine.png';
import stage21  from '../../../assets/img/ProductionProcessImages/Injection Molding/VIFO.png';
import stage22  from '../../../assets/img/ProductionProcessImages/Injection Molding/VIFL.png';

import prod1   from '../../../assets/img/ProductionProcessImages/Injection Molding/rubber.png';
import prod21  from '../../../assets/img/ProductionProcessImages/Injection Molding/Automotive rubber wiring harness protective sleeve.png';
import prod22  from '../../../assets/img/ProductionProcessImages/Injection Molding/automotive rubber bushing.png';


const hotPressProcessImages = {
    stages: {
        "Two-Roll Mill Machine": [stage1],
        "Vertical Rubber Injection Molding Machine": [stage21, stage22],
    },
  
    products: {
        "Two-Roll Mill Machine": [prod1],
        "Vertical Rubber Injection Molding Machine": [prod21, prod22],
    }
  };
  
  export default hotPressProcessImages;
