import React from 'react';

const LV1Spec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>100LV-1</th>
                            <th>200LV-1</th>
                            <th>300LV-1</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force(cc)</td>
                            <td>100</td>
                            <td>200</td>
                            <td>300</td>
                        </tr>
                        <tr>
                            <td>Max. Injection Rate(cc/s)</td>
                            <td>300</td>
                            <td>300</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Injection Pressure(kgf/cm2)</td>
                            <td>1000</td>
                            <td>1000</td>
                            <td>1200</td>
                        </tr>
                        <tr>
                            <td>Injection Volume(cc)</td>
                            <td>2000</td>
                            <td>3000</td>
                            <td>5000</td>
                        </tr>
                        <tr>
                            <td>Platen Size(mm)</td>
                            <td>400*400</td>
                            <td>550*560</td>
                            <td>700*700</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter(mm)</td>
                            <td>Φ 250</td>
                            <td>Φ 360</td>
                            <td>Φ 450</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke(mm)</td>
                            <td>350</td>
                            <td>400</td>
                            <td>600</td>
                        </tr>
                        <tr>
                            <td>Min. Mold Thickness(mm)</td>
                            <td>51</td>
                            <td>110</td>
                            <td>110</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance(mm)</td>
                            <td>300~400</td>
                            <td>500~600</td>
                            <td>600~700</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="3">2RT-3RT-4RT Overhead Suspension Mold-Bottom Suspension Mold</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure(MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Heating Power(KW)</td>
                            <td>10</td>
                            <td>15.3</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>Gross Weight(Ton)</td>
                            <td>6</td>
                            <td>7.3</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H(mm)</td>
                            <td>2400*1650*3200</td>
                            <td>2600*2000*4000</td>
                            <td>2750*2265*4200</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LV1Spec;