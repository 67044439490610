import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ServicesOneCard({ bgImg, icon, heading, btnText, catLink }) {
    return (
        <div className="col-md-6 col-xl-3 col-12">
            <div className="single-service-item service-1">
                <div
                    className="service-bg bg-cover"
                    style={{
                        backgroundImage: `url(${bgImg})`,
                    }}
                />
                <div
                    className="icon"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px',
                    }}
                >
                    <img src={icon} alt="icon" style={{ maxHeight: '100%' }} />
                </div>
                <h3>{heading}</h3>
                <Link to={catLink}>
                    <span>{btnText}</span>
                    <BsArrowRight style={{ fontSize: '18px' }} />
                </Link>
            </div>
        </div>
    );
}

export default ServicesOneCard;
