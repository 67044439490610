import React from 'react';
import ContactFormContent from './ContactFormContent';

function ContactForm({ title, heading }) {
    return (
        <section className="contact-form-wrapper section-padding pt-0">
            <div className="container">
                <ContactFormContent title={title} heading={heading} />
            </div>
        </section>
    );
}

export default ContactForm;