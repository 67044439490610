import img1  from '../../../assets/img/machines/Rubber Strip Cutting Machine/Rubber CNC Strip Cutting Machine.png';

import RubberStripCuttingMachineSpec from '../../../components/MachineSpec/RubberStripCuttingMachineSpec';

const rubberCuttingData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Rubber Strip Cutting Machine' },
    ],
    specTable: <RubberStripCuttingMachineSpec />
};

export default rubberCuttingData;
