import { AiOutlineMail } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdOutlineTextsms } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Email Address',
        text: 'Send Email',
        item1: 'info@juchuanmachines.com',
        refLink: 'mailto:info@juchuanmachines.com'
    },

    {
        id: uuidv4(),
        icon: <MdOutlineTextsms />,
        heading: 'Phone Number',
        text: 'Text Us',
        item1: '(+1) 719-JUCHUAN',
        refLink: 'sms:+17195824826'
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'Address',
        text: 'Visit Us',
        item1: '16 Liyu Street, Nansha District, Guangzhou',
    },
];

export default contactData;
