import React from 'react';

function ContactItem({ icon, heading, text, item1, item2, refLink }) {
    return (
        <div className="col-lg-4 col-md-6 col-12">
            <div className="single-contact-card card1">
                <div className="top-part">
                    {refLink ? (
                        <div className="icon">
                            <a href={refLink}>
                                {icon}
                            </a>
                        </div>
                    ) : (
                        <div className="icon">{icon}</div>
                    )}
                    <div className="title">
                        <h4>{heading}</h4>
                        <span>{text}</span>
                    </div>
                </div>
                <div className="bottom-part">
                    <div className="info">
                        <p>{item1}</p>
                        <p>{item2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactItem;
