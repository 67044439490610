import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import AgeImg from '../../assets/img/age.png';

function Promo() {
    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-6 col-12 text-center">
                        <img src={AgeImg} alt="Juchuan Machinery Year of Experience" />
                        <h4>
                            Years Of Experience With <b>Innovative Manufacturing</b>
                        </h4>
                    </div>
                    <div className="col-xl-6 col-12">
                        <div className="block-contents ml-xl-5 mt-5 mt-xl-0">
                            <span>Engineering Excellence</span>
                            <h1>Your Trusted Machinery Partner</h1>
                            <h4>
                                Crafting Innovative Solutions with Decades of Precision Engineering
                            </h4>
                            <p>
                                Premier equipment manufacturer with a range of over 20 specialized processing machines. Our expertise spans research and development, manufacturing, sales, and comprehensive after-sales service, delivering end-to-end solutions in machinery production.
                            </p>
                            <Link to="/contact" className="theme-btn">
                                Get In Touch
                                <BsArrowRight style={{ fontSize: '20px', marginLeft: '15px' }} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Promo;
