import stage1  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Rubber Two-Roll Mill Machine.png';
import stage2  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Rubber Strip Cutting Machine.jpg';
import stage3  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Vacuum Compression Molding Machine.png';
import stage4  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Stainless Steel Hot Air Rotary Drum Dryer.png';

import prod1  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Solid Silicone.jpg';
import prod2  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Semi-finished silicone spoon.jpg';
import prod3  from '../../../assets/img/ProductionProcessImages/Silicone Spoon/Silicone spoon.png';


const compressionProcessImages = {
    stages: {
        "Two-Roll Mill Machine": [stage1],
        "Rubber Strip Cutting Machine": [stage2],
        "Vacuum Compression Molding Machine": [stage3],
        "Stainless Steel Hot Air Rotary Drum Dryer": [stage4],
    },
  
    products: {
        "Two-Roll Mill Machine": [prod1],
        "Vacuum Compression Molding Machine": [prod2],
        "Stainless Steel Hot Air Rotary Drum Dryer": [prod3],
    }
  };
  
  export default compressionProcessImages;