import { v4 as uuidv4 } from 'uuid';
import vfVacuumMoldingThumb from '../../assets/img/machines/vf-vacuum-molding/vf-vacuum-molding-front-small.png';
import hfHotPressThumb from '../../assets/img/machines/hf-hot-press/hf-hot-press-2-small.png';
import viaoThumb from '../../assets/img/machines/vi-ao-rubber-injection/viao-2-small.png';
import vifoThumb from '../../assets/img/machines/vi-fo-rubber-injection/vifo-white-small.png';
import viflThumb  from '../../assets/img/machines/vi-fl-rubber-injection/vifl-front-small.png';
import viaoSiliconThumb  from '../../assets/img/machines/vi-ao-silicone-injection/viao-small.png';
import hiflRubberThumb  from '../../assets/img/machines/hi-fl-rubber-injection/hifl-rubber-small.png';
import hiflSiliconThumb  from '../../assets/img/machines/hi-fl-silicone-injection/hifl-small.png';
import lv1Thumb  from '../../assets/img/machines/lv-1-silicone-injection/lv1-main-small.png';
import lv2Thumb  from '../../assets/img/machines/lv-2-silicone-molding/lv2-small.png';
import tpThumb  from '../../assets/img/machines/tp-press-molding/tp-small.png';
import brakeHpThumb  from '../../assets/img/machines/hf-brake/brake4cyl-small.png';

import millThumb from '../../assets/img/machines/Rubber Two-Roll Mill Machine/Rubber Two-Roll Mill Machine-small.png';
import washThumb from '../../assets/img/machines/Antistatic Washing and Drying Machine/Antistatic Washing and Drying Machine 40KG-small.jpeg';
import hotDryThumb from '../../assets/img/machines/Stainless Steel Hot Air Rotary Drum Dryer/Hot Air Rotary Drum Dryer-small.jpeg';
import deflashingThumb from '../../assets/img/machines/Rubber Deflashing Machine/Rubber Deflashing Machine-small.jpg';
import siliconeCuttingThumb from '../../assets/img/machines/Silicone Strip Cutting Machine/Silicone CNC Strip Cutting Machine-small.jpeg';
import rubberCuttingThumb from '../../assets/img/machines/Rubber Strip Cutting Machine/Rubber CNC Strip Cutting Machine-small.jpeg';
import openMillThumb from '../../assets/img/machines/Two-Roll Open Mill for Rubber Mixing/Two-Roll Mill for Rubber Mixing-small.jpeg';
import feederThumb from '../../assets/img/machines/Air Separation Machine with Automatic Feeder/Air Separation Machine with Automatic Feeder-small.jpeg';
import punchingThumb from '../../assets/img/machines/Punching Machine/Punching Machine-small.jpg';

const productsTwoData = [
    {
        id: uuidv4(),
        thumbnail: vfVacuumMoldingThumb,
        heading: 'Vacuum Compression Molding Machine',
        link: "/machine/vacuum-compression-molding-machine",
        category: ["rubber", "silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: hfHotPressThumb,
        heading: 'Hydraulic Vulcanizing Hot Press Machine',
        link: "/machine/hydraulic-hot-press-machine",
        category: ["rubber", "silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: viaoThumb,
        heading: 'VI-AO Vertical Rubber Injection Molding Machine',
        link: "/machine/vertical-rubber-injection-molding-machine/vi-ao",
        category: ["rubber"],
    },

    {
        id: uuidv4(),
        thumbnail: vifoThumb,
        heading: 'VI-FO Vertical Rubber Injection Molding Machine',
        link: "/machine/vertical-rubber-injection-molding-machine/vi-fo",
        category: ["rubber"],
    },

    {
        id: uuidv4(),
        thumbnail: viflThumb,
        heading: 'VI-FL Vertical Rubber Injection Molding Machine',
        link: "/machine/vertical-rubber-injection-molding-machine/vi-fl",
        category: ["rubber"],
    },

    {
        id: uuidv4(),
        thumbnail: hiflRubberThumb,
        heading: 'HI-FL Horizontal Rubber Injection Molding Machine',
        link: "/machine/horizontal-rubber-injection-molding-machine/hi-fl",
        category: ["rubber"],
    },

    {
        id: uuidv4(),
        thumbnail: viaoSiliconThumb,
        heading: 'VI-AO Vertical Solid Silicone Injection Molding Machine',
        link: "/machine/vertical-solid-silicone-injection-molding-machine/vi-ao",
        category: ["silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: hiflSiliconThumb,
        heading: 'HI-FL Horizontal Silicone Injection Molding Machine',
        link: "/machine/horizontal-silicone-injection-molding-machine/hi-fl",
        category: ["silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: lv1Thumb,
        heading: 'LV-1 Vertical Liquid Silicone Injection Molding Machine',
        link: "/machine/vertical-liquid-silicone-injection-molding-machine",
        category: ["silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: lv2Thumb,
        heading: 'LV-2 Solid/Liquid Silicone Molding Machine',
        link: "/machine/solid-liquid-silicone-molding-machine",
        category: ["silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: tpThumb,
        heading: 'Vertical Transfer Press Molding Machine',
        link: "/machine/vertical-transfer-press-molding-machine",
        category: ["silicone"],
    },

    {
        id: uuidv4(),
        thumbnail: brakeHpThumb,
        heading: 'Brake Pad and Lining Hot Press Machine',
        link: "/machine/brake-pad-and-lining-hot-press-machine",
        category: ["brake"],
    },

    {
        id: uuidv4(),
        thumbnail: millThumb,
        heading: 'Rubber Two-Roll Mill Machine',
        link: "/machine/rubber-two-roll-mill-machine",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: washThumb,
        heading: 'Antistatic Washing and Drying Machine',
        link: "/machine/antistatic-washing-drying-machine",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: hotDryThumb,
        heading: 'Stainless Steel Hot Air Rotary Drum Dryer',
        link: "/machine/stainless-steel-hot-air-rotary-drum-dryer",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: deflashingThumb,
        heading: 'Rubber Deflashing Machine',
        link: "/machine/rubber-deflashing-machine",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: siliconeCuttingThumb,
        heading: 'Silicone Strip Cutting Machine',
        link: "/machine/silicone-strip-cutting-machine",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: rubberCuttingThumb,
        heading: 'Rubber Strip Cutting Machine',
        link: "/machine/rubber-strip-cutting-machine",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: openMillThumb,
        heading: 'Two-Roll Open Mill Machine for Rubber Mixing',
        link: "/machine/two-roll-open-mill-machine-for-rubber-mixing",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: feederThumb,
        heading: 'Air Separation Machine with Automatic Feeder',
        link: "/machine/air-separation-machine-with-automatic-feeder",
        category: ["auxiliary"],
    },
    {
        id: uuidv4(),
        thumbnail: punchingThumb,
        heading: 'Punching Machine',
        link: "/machine/punching-machine",
        category: ["auxiliary"],
    }
];

export default productsTwoData;
