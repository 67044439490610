import React from 'react';

const VFSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>100VF</th>
                            <th>200VF</th>
                            <th>250VF</th>
                            <th>300VF</th>
                            <th>300VF</th>
                            <th>300VF</th>
                            <th>350VF</th>
                            <th>400VF</th>
                            <th>800VF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force (Ton)</td>
                            <td>100</td>
                            <td>200</td>
                            <td>250</td>
                            <td>300</td>
                            <td>300</td>
                            <td>350</td>
                            <td>400</td>
                            <td>500</td>
                            <td>800</td>
                        </tr>
                        <tr>
                            <td>Upper Platen Size (mm)</td>
                            <td>380*400</td>
                            <td>510*510</td>
                            <td>600*600</td>
                            <td>600*600</td>
                            <td>680*680</td>
                            <td>700*700</td>
                            <td>760*900</td>
                            <td>1200*700</td>
                            <td>1600*1100</td>
                        </tr>
                        <tr>
                            <td>Lower Platen Size (mm)</td>
                            <td>400*460</td>
                            <td>550*560</td>
                            <td>650*600</td>
                            <td>650*600</td>
                            <td>700*800</td>
                            <td>700*700</td>
                            <td>900*1060</td>
                            <td>1200*700</td>
                            <td>1750*1300</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter (mm)</td>
                            <td>Φ 250</td>
                            <td>Φ 360</td>
                            <td>Φ 400</td>
                            <td>Φ 450</td>
                            <td>Φ 450</td>
                            <td>Φ 480</td>
                            <td colSpan="3">Customized</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke (mm)</td>
                            <td>200</td>
                            <td>250</td>
                            <td>250</td>
                            <td>250</td>
                            <td>300</td>
                            <td>300~400</td>
                            <td>400</td>
                            <td>300</td>
                            <td>350</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance (mm)</td>
                            <td>200</td>
                            <td>250</td>
                            <td>250</td>
                            <td>250</td>
                            <td>300</td>
                            <td>300</td>
                            <td>400</td>
                            <td>300</td>
                            <td>350</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance (mm)</td>
                            <td>490*230</td>
                            <td>640*310</td>
                            <td>745*310</td>
                            <td>745*310</td>
                            <td>785*380</td>
                            <td>/</td>
                            <td>920*380</td>
                            <td>1321*345</td>
                            <td>1840*570</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="9">2RT-3RT-4RT-Track</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure (MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power (KW)</td>
                            <td>5.5*2</td>
                            <td>7.5*2</td>
                            <td>7.5*2</td>
                            <td>11*2</td>
                            <td>11*2</td>
                            <td>11*2</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Heating Power (KW)</td>
                            <td>7.2*2</td>
                            <td>13.5*2</td>
                            <td>15.3*2</td>
                            <td>15.3*2</td>
                            <td>24*2</td>
                            <td>24*2</td>
                            <td>25</td>
                            <td>30</td>
                            <td>90</td>
                        </tr>
                        <tr>
                            <td>Total Power(KW)</td>
                            <td>28.4</td>
                            <td>45</td>
                            <td>48.8</td>
                            <td>48.8</td>
                            <td>66.2</td>
                            <td>73</td>
                            <td>39</td>
                            <td>44</td>
                            <td>104</td>
                        </tr>
                        <tr>
                            <td>Gross Weight (Ton)</td>
                            <td>5</td>
                            <td>7.5</td>
                            <td>11</td>
                            <td>11</td>
                            <td>15.45</td>
                            <td>/</td>
                            <td>/</td>
                            <td>/</td>
                            <td>/</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H (mm)</td>
                            <td>2500*2450*2160</td>
                            <td>2930*2500*2350</td>
                            <td>3190*2930*2495</td>
                            <td>3190*2930*2495</td>
                            <td>3360*2970*2850</td>
                            <td colSpan="4">Customized</td>
                        </tr>
                        <tr>
                            <td>Vacuum Pump Power (KW)</td>
                            <td>3</td>
                            <td>3</td>
                            <td>3.2</td>
                            <td>3.2</td>
                            <td>3.2</td>
                            <td>3</td>
                            <td>3</td>
                            <td>3</td>
                            <td>3</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VFSpec;