import React from 'react';

function CorporateCultureCard({ thumbnail, heading, text }) {
    return (
        <>
            <div className="col-md-6 col-xl-4 col-12">
                <div className="single-approch-card">
                    <div
                        className="card-thumb bg-cover"
                        style={{
                            backgroundImage: `url(${thumbnail})`,
                        }}
                    />
                    <div className="content" style={{ minHeight: '250px', textAlign: 'center' }}>
                        <h3 style={{ color: '#777' }}>{heading}</h3>
                        <p style={{ color: 'black' }}>{text}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CorporateCultureCard;