import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import techOneData from './techOneData';
import TechOneSlide from './TechOneSlide';

SwiperCore.use([Navigation]);

function Technologies() {
    return (
        <section className="portfolio-section section-padding pt-5">
            <div className="container">
                <div className="project-wrapper">
                    <Swiper navigation className="portfolio-carousel-active owl-carousel">
                        {techOneData.map((data) => (
                            <SwiperSlide key={data.id}>
                                <TechOneSlide
                                    image={data.image}
                                    imgLink={data.imgLink}
                                    title={data.heading}
                                    desc={data.desc}
                                    other={data.other}
                                    keywords={data.keywords}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default Technologies;
