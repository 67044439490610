import React from 'react';
import bannerBg from '../assets/img/juchuan-page-banner.jpeg';
import ContactForm from '../components/ContactForm';
import FaqAccordion from '../components/FaqAccordion';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';

function Faq() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} heading="Get Answers" currentPage="Faq" />
            <section className="faq-section section-padding">
                <ContactForm title="Get Quote" heading="Get More Answers" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 wow fadeInUp">
                            <div className="faq-content">
                                <FaqAccordion
                                    question="Can you customize your rubber and silicone molding machines?"
                                    answer="Absolutely. We offer customization services for our molding machines to meet your specific rubber and silicone production requirements."
                                />
                                <FaqAccordion
                                    question="What cylinder technology do you use in your molding machines?"
                                    answer="We utilize advanced One-Time Molded Cylinder Design in our rubber and silicone molding machines. This innovative approach eliminates the need for screw locking, unlike the traditional Flange Cylinder Design used by some competitors."
                                />
                                <FaqAccordion
                                    question="What type of plunger is used in your rubber and silicone molding equipment?"
                                    answer="Our machines are equipped with solid, high-strength plungers, ensuring durability and precision in rubber and silicone molding processes. This contrasts with less robust, non-solid plungers used in some other machines."
                                />
                                <FaqAccordion
                                    question="How can I determine if your molding machine is suitable for my rubber or silicone product?"
                                    answer="To ensure you select the ideal machine for your rubber or silicone product, we recommend completing the inquiry form on this page. Provide a detailed description of your product and production goals, and our experienced team will promptly offer personalized recommendations based on your specific needs."
                                />
                                <FaqAccordion
                                    question="What are your payment terms for rubber and silicone molding machines?"
                                    answer="We offer two flexible payment options: 1. T/T (Telegraphic Transfer): 30% deposit, with the remaining 70% due before shipment; 2. 100% irrevocable Letter of Credit (LC) at sight."
                                />
                                <FaqAccordion
                                    question="What is the typical lead time for your molding machines?"
                                    answer="For our standard rubber compression molding machines, the lead time is approximately 35 days. For standard silicone injection molding machines, it's about 90 days."
                                />
                                <FaqAccordion
                                    question="What warranty do you provide with your rubber and silicone molding equipment?"
                                    answer="We offer a comprehensive one-year warranty on all our molding equipment. This is complemented by 24/7 technical support via email and optional online training for optimal operation of your rubber and silicone molding machines."
                                />
                                <FaqAccordion
                                    question="How do your molding machines enhance rubber and silicone production efficiency?"
                                    answer="Our advanced cylinder design and solid plunger technology contribute to increased precision, reduced maintenance, and improved overall efficiency in rubber and silicone molding processes."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <ContactForm title="Get Quote" heading="Get More Answers" /> */}
            <Footer1 />
        </>
    );
}

export default Faq;
