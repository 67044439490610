import React from 'react';

const BrakeHPSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>200HF</th>
                            <th>300HF</th>
                            <th>400HF</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force(Ton)</td>
                            <td>200</td>
                            <td>300</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Platen Size(mm)</td>
                            <td>550*560</td>
                            <td>700*800</td>
                            <td>700*700</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter(mm)</td>
                            <td>Φ 360</td>
                            <td>Φ 450</td>
                            <td>Φ 500</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke(mm)</td>
                            <td>250</td>
                            <td>300</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance (mm)</td>
                            <td>250 ~ 350</td>
                            <td>300 ~ 400</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure(MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Moto Power(KW)</td>
                            <td>7.5*2</td>
                            <td>11*2</td>
                            <td>11*2</td>
                        </tr>
                        <tr>
                            <td>Heating Power(KW)</td>
                            <td>13.5*2</td>
                            <td>24*2</td>
                            <td>26.4*2</td>
                        </tr>
                        <tr>
                            <td>Total Power(KW)</td>
                            <td>42</td>
                            <td>63</td>
                            <td>74.8</td>
                        </tr>
                        <tr>
                            <td>Gross Weight(Ton)</td>
                            <td>7.8</td>
                            <td>15</td>
                            <td>17</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H(mm)</td>
                            <td>2930*2350*2050</td>
                            <td>3360*2970*2250</td>
                            <td>3660*3050*2350</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BrakeHPSpec;