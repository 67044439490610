import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/certificates/30.png';
import thumb2 from '../../assets/img/certificates/31.png';
import thumb3 from '../../assets/img/certificates/32.png';
import thumb4 from '../../assets/img/certificates/33.png';
import thumb5 from '../../assets/img/certificates/34.png';
import thumb6 from '../../assets/img/certificates/35.png';


const certificationData = [
    {
        id: uuidv4(),
        thumb: thumb3,
    },
    {
        id: uuidv4(),
        thumb: thumb4,
    },
    {
        id: uuidv4(),
        thumb: thumb6,
    },
    {
        id: uuidv4(),
        thumb: thumb5,
    },
    {
        id: uuidv4(),
        thumb: thumb1,
    },
    {
        id: uuidv4(),
        thumb: thumb2,
    },
];

export default certificationData;
