import React from 'react';

function AdvantageDetailsCard({ icon, num, heading, text }) {
    return (
        <div className="col-lg-3 col-md-6 col-12">
            <div className="single-process-box">
                <h2>{num}</h2>
                <div className="icon">
                    {icon}
                </div>
                <div className="content">
                    <h3>{heading}</h3>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}

export default AdvantageDetailsCard;
