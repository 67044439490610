import React from 'react';
import CorporateCultureCard from './CorporateCultureCard';
import corporateCultureData from './corporateCultureData';

function CorporateCulture() {
    return (
        <section className="our-approch-wrapper section-padding">
            <div className="container">
                <div className="row mb-30">
                    <div className="col-12 col-lg-12">
                        <div className="section-title text-center">
                            <p>Meeting Global Excellence Standards</p>
                            <h1>Our Corporate Culture</h1>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {corporateCultureData.map((data) => (
                        <CorporateCultureCard
                            key={data.id}
                            thumbnail={data.thumb}
                            heading={data.heading}
                            text={data.text}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CorporateCulture;
