import React from 'react';

const VIFLSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Key Technical Parameters</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>100VI-FL</th>
                            <th>200VI-FL</th>
                            <th>300VI-FL</th>
                            <th>400VI-FL</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Clamp Force (Ton)</td>
                            <td>100</td>
                            <td>200</td>
                            <td>300</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>Injection Volume (cc)</td>
                            <td>1000</td>
                            <td>2000</td>
                            <td>3000</td>
                            <td>4000</td>
                        </tr>
                        <tr>
                            <td>Injection Pressure (kgf/cm2)</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                            <td>2000</td>
                        </tr>
                        <tr>
                            <td>Platen Size (mm)</td>
                            <td>400*400</td>
                            <td>550*560</td>
                            <td>700*700</td>
                            <td>700*700 (800*1000)</td>
                        </tr>
                        <tr>
                            <td>Plunger Diameter (mm)</td>
                            <td>Φ 250</td>
                            <td>Φ 360</td>
                            <td>Φ 450</td>
                            <td>Φ 500</td>
                        </tr>
                        <tr>
                            <td>Min. Mold Thickness (mm)</td>
                            <td>50</td>
                            <td>100</td>
                            <td>100</td>
                            <td>100</td>
                        </tr>
                        <tr>
                            <td>Plunger Stroke (mm)</td>
                            <td>350</td>
                            <td>400</td>
                            <td>400 (600)</td>
                            <td>400 (500)</td>
                        </tr>
                        <tr>
                            <td>Heating Plates Distance (mm)</td>
                            <td>400~500</td>
                            <td>500~600</td>
                            <td>500~600</td>
                            <td>500~600</td>
                        </tr>
                        <tr>
                            <td>Tie Bar Distance (mm)</td>
                            <td>515*230</td>
                            <td>640*310</td>
                            <td>820*395</td>
                            <td>820*395</td>
                        </tr>
                        <tr>
                            <td>Mold Opening Type</td>
                            <td colSpan="4">2RT-3RT-4RT Overhead Suspension Mold-Bottom Suspension Mold</td>
                        </tr>
                        <tr>
                            <td>Max. Operating Pressure (MPa)</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Motor Power (KW)</td>
                            <td>7.5</td>
                            <td>11</td>
                            <td>11</td>
                            <td>11</td>
                        </tr>
                        <tr>
                            <td>Heating Power (KW)</td>
                            <td>10</td>
                            <td>15.3</td>
                            <td>24</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>Total Power (KW)</td>
                            <td>17.5</td>
                            <td>26.3</td>
                            <td>35</td>
                            <td>35</td>
                        </tr>
                        <tr>
                            <td>Gross Weight (Ton)</td>
                            <td>6.4</td>
                            <td>7.8</td>
                            <td>9.6</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>Machine Size L*W*H (mm)</td>
                            <td>2400*1650*3200</td>
                            <td>2600*2000*4000</td>
                            <td>2750*2265*4200</td>
                            <td>2750*2265*4200/ 3700*3800*4950</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VIFLSpec;