/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

function FormInput({ labelFor, label, placeholder, id, type, value, onChange, autoComplete, required }) {
    return (
        <div className="col-md-6 col-12">
            <div className="single-personal-info">
                <label htmlFor={labelFor}>{label}{required && <span className="required">*</span>}</label>
                <input
                    value={value}
                    onChange={onChange}
                    type={type}
                    id={id}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    required={required}
                />
            </div>
        </div>
    );
}

export default FormInput;
