import img1  from '../../../assets/img/machines/Two-Roll Open Mill for Rubber Mixing/Two-Roll Mill for Rubber Mixing.png';

import prod1  from '../../../assets/img/machines/Two-Roll Open Mill for Rubber Mixing/app_products/Custom rubber sheets produced by advanced rubber manufacturing equipment.png';
import prod2  from '../../../assets/img/machines/Two-Roll Open Mill for Rubber Mixing/app_products/Diverse rubber products from industrial rubber processing machinery.png';
import prod3  from '../../../assets/img/machines/Two-Roll Open Mill for Rubber Mixing/app_products/Multi-colored rubber materials crafted with versatile rubber production technology.png';

const openMillData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Two-Roll Open Mill for Rubber Mixing' },
    ],
    applicationImages: [
        { img: prod1, alt: 'Colorful rubber rolls in yellow, blue, white, and red, showcasing the output variety of rubber molding machines' },
        { img: prod2, alt: 'Stacked orange, yellow, and teal rubber sheets, demonstrating the capabilities of industrial rubber processing machines' },
        { img: prod3, alt: 'Assorted rubber rolls and sheets in green, red, black, orange, and blue, highlighting the diverse applications of rubber molding machinery' },
    ],
};

export default openMillData;
