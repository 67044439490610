import React from 'react';

const AntistaticWashingDryingMachineSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Antistatic Washing and Drying Machine Specifications</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Model</th>
                            <th>DSR - 198 - 20KG</th>
                            <th>DSR - 198 - 40KG</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Max Single Load</td>
                            <td>20KG</td>
                            <td>40KG</td>
                        </tr>
                        <tr>
                            <td>Total Power</td>
                            <td>12KW</td>
                            <td>15KW</td>
                        </tr>
                        <tr>
                            <td>Voltage</td>
                            <td>AC380V</td>
                            <td>AC380V</td>
                        </tr>
                        <tr>
                            <td>Machine Dimensions (L x W x H)</td>
                            <td>1410mm x 1340mm x 1520mm</td>
                            <td>1810mm x 1540mm x 1720mm</td>
                        </tr>
                        <tr>
                            <td>Applicable Industries</td>
                            <td colSpan="2">Suitable for rubber, machinery, electronics, electric power, instruments, meters, petroleum, chemical, aviation, and automotive industries.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AntistaticWashingDryingMachineSpec;