import { v4 as uuidv4 } from 'uuid';
import logo1  from '../../assets/img/clients/c1.png';
import logo2  from '../../assets/img/clients/c2.png';
import logo3  from '../../assets/img/clients/c3.png';
import logo4  from '../../assets/img/clients/c4.png';
import logo5  from '../../assets/img/clients/c5.png';
import logo6  from '../../assets/img/clients/c6.png';
import logo7  from '../../assets/img/clients/c7.png';
import logo8  from '../../assets/img/clients/c8.png';
import logo9  from '../../assets/img/clients/c9.png';
import logo10 from '../../assets/img/clients/c10.png';
import logo11  from '../../assets/img/clients/c11.png';
import logo12  from '../../assets/img/clients/c12.png';
import logo13  from '../../assets/img/clients/c13.png';
import logo14  from '../../assets/img/clients/c14.png';
import logo15  from '../../assets/img/clients/c15.png';
import logo16  from '../../assets/img/clients/c16.png';
import logo17  from '../../assets/img/clients/c17.png';
import logo18  from '../../assets/img/clients/c18.png';
import logo19  from '../../assets/img/clients/c19.png';
import logo20  from '../../assets/img/clients/c20.png';
import logo21  from '../../assets/img/clients/c21.png';
import logo22  from '../../assets/img/clients/c22.png';
import logo23  from '../../assets/img/clients/c23.png';
import logo24  from '../../assets/img/clients/c24.png';
import logo25  from '../../assets/img/clients/c25.png';
import logo26  from '../../assets/img/clients/c26.png';
import logo27  from '../../assets/img/clients/c27.png';
import logo28  from '../../assets/img/clients/c28.png';
import logo29  from '../../assets/img/clients/c29.png';
import logo30  from '../../assets/img/clients/c30.png';

const sponsorData = [
    {id: uuidv4(), sponsorLogo: logo1},
    {id: uuidv4(), sponsorLogo: logo2},
    {id: uuidv4(), sponsorLogo: logo3},
    {id: uuidv4(), sponsorLogo: logo4},
    {id: uuidv4(), sponsorLogo: logo5},
    {id: uuidv4(), sponsorLogo: logo6},
    {id: uuidv4(), sponsorLogo: logo7},
    {id: uuidv4(), sponsorLogo: logo8},
    {id: uuidv4(), sponsorLogo: logo9},
    {id: uuidv4(), sponsorLogo: logo10},

    {id: uuidv4(), sponsorLogo: logo11},
    {id: uuidv4(), sponsorLogo: logo12},
    {id: uuidv4(), sponsorLogo: logo13},
    {id: uuidv4(), sponsorLogo: logo14},
    {id: uuidv4(), sponsorLogo: logo15},
    {id: uuidv4(), sponsorLogo: logo16},
    {id: uuidv4(), sponsorLogo: logo17},
    {id: uuidv4(), sponsorLogo: logo18},
    {id: uuidv4(), sponsorLogo: logo19},
    {id: uuidv4(), sponsorLogo: logo20},

    {id: uuidv4(), sponsorLogo: logo21},
    {id: uuidv4(), sponsorLogo: logo22},
    {id: uuidv4(), sponsorLogo: logo23},
    {id: uuidv4(), sponsorLogo: logo24},
    {id: uuidv4(), sponsorLogo: logo25},
    {id: uuidv4(), sponsorLogo: logo26},
    {id: uuidv4(), sponsorLogo: logo27},
    {id: uuidv4(), sponsorLogo: logo28},
    {id: uuidv4(), sponsorLogo: logo29},
    {id: uuidv4(), sponsorLogo: logo30},
];

export default sponsorData;
