import React from 'react';

const RubberTwoRollMillSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Rubber Two-Roll Mill Machine Specifications</h2>
            <div style={{ overflowX: 'auto' }}>
                <table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-blue-600 text-white">
                            <th className="border border-gray-300 p-2">Specifications</th>
                            <th className="border border-gray-300 p-2">X(s)k-160</th>
                            <th className="border border-gray-300 p-2">X(s)k-400</th>
                            <th className="border border-gray-300 p-2">X(s)k-450</th>
                            <th className="border border-gray-300 p-2">X(s)k-230</th>
                            <th className="border border-gray-300 p-2">X(s)k-300</th>
                            <th className="border border-gray-300 p-2">X(s)k-360</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 p-2">Roll Diameter (mm)</td>
                            <td className="border border-gray-300 p-2">160</td>
                            <td className="border border-gray-300 p-2">400</td>
                            <td className="border border-gray-300 p-2">450</td>
                            <td className="border border-gray-300 p-2">230</td>
                            <td className="border border-gray-300 p-2">300</td>
                            <td className="border border-gray-300 p-2">360</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Working Length of Roll (mm)</td>
                            <td className="border border-gray-300 p-2">320</td>
                            <td className="border border-gray-300 p-2">1000</td>
                            <td className="border border-gray-300 p-2">1200</td>
                            <td className="border border-gray-300 p-2">630</td>
                            <td className="border border-gray-300 p-2">700</td>
                            <td className="border border-gray-300 p-2">900</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Front and Rear Roll Speed Ratio</td>
                            <td className="border border-gray-300 p-2">1:1.35</td>
                            <td className="border border-gray-300 p-2">1:1.27</td>
                            <td className="border border-gray-300 p-2">1:1.27</td>
                            <td className="border border-gray-300 p-2">1:1.19</td>
                            <td className="border border-gray-300 p-2">1:1.15</td>
                            <td className="border border-gray-300 p-2">1:1.25</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Front Roll Linear Speed (m/min)</td>
                            <td className="border border-gray-300 p-2">8.95</td>
                            <td className="border border-gray-300 p-2">18.62</td>
                            <td className="border border-gray-300 p-2">24.30</td>
                            <td className="border border-gray-300 p-2">10.43</td>
                            <td className="border border-gray-300 p-2">14.84</td>
                            <td className="border border-gray-300 p-2">16.25</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Single Batch Feeding Capacity (kg)</td>
                            <td className="border border-gray-300 p-2">1-2</td>
                            <td className="border border-gray-300 p-2">25-35</td>
                            <td className="border border-gray-300 p-2">30-50</td>
                            <td className="border border-gray-300 p-2">5-10</td>
                            <td className="border border-gray-300 p-2">15-20</td>
                            <td className="border border-gray-300 p-2">20-25</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Main Motor Power (kW)</td>
                            <td className="border border-gray-300 p-2">5.5</td>
                            <td className="border border-gray-300 p-2">37</td>
                            <td className="border border-gray-300 p-2">55</td>
                            <td className="border border-gray-300 p-2">11</td>
                            <td className="border border-gray-300 p-2">22</td>
                            <td className="border border-gray-300 p-2">30</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Overall Dimensions L*W*H (mm)</td>
                            <td className="border border-gray-300 p-2">1200x1000x1400</td>
                            <td className="border border-gray-300 p-2">4200x1800x1800</td>
                            <td className="border border-gray-300 p-2">1200x1000x1400</td>
                            <td className="border border-gray-300 p-2">1200x1000x1400</td>
                            <td className="border border-gray-300 p-2">1200x1000x1400</td>
                            <td className="border border-gray-300 p-2">1200x1000x1400</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 p-2">Weight (kg)</td>
                            <td className="border border-gray-300 p-2">1200</td>
                            <td className="border border-gray-300 p-2">8800</td>
                            <td className="border border-gray-300 p-2">13000</td>
                            <td className="border border-gray-300 p-2">2500</td>
                            <td className="border border-gray-300 p-2">4500</td>
                            <td className="border border-gray-300 p-2">5700</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RubberTwoRollMillSpec;