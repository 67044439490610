import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function TechOneSlide({ image, title, desc, other, keywords }) {
    // Function to highlight keywords in text
    const highlightKeywords = (text, keywords) => {
        if (!keywords || keywords.length === 0) return text;
        
        const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
        return text.split(regex).map((part, index) => 
            regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
    };

    return (
        <div className="single-project">
            <div className="project-contents">
                <div className="row">
                    <div className="project-details col-lg-4 offset-lg-1 pl-lg-0 order-2 order-lg-1 col-12">
                        <h2>{title}</h2>
                        <h4><CheckIcon/> Machine by Juchuan:</h4>
                        <p>{highlightKeywords(desc, keywords)}</p>
                        <h5><CloseIcon/> Machine by Other Company:</h5>
                        <p>{other}</p>
                    </div>
                    <div className="project-thumbnail col-lg-5 offset-lg-1 p-lg-0 order-1 order-lg-2 col-12">
                        <div className="image-wrapper">
                            <img src={image} alt={title} className="project-image" loading="lazy"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechOneSlide;