import React from 'react';
import Cta from '../components/Cta';
import GlobalPresence from '../components/GlobalPresence';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import Hero1 from '../components/Hero1';
import Advantages from '../components/Advantages';
import Promo from '../components/Promo';
import Services1 from '../components/Services1';

function Home1() {
    return (
        <>
            <Header1 />
            <Hero1 />
            <Promo />
            <Services1 />
            <Cta />
            <GlobalPresence />
            <Advantages />
            <Footer1 />
        </>
    );
}

export default Home1;
