import React from 'react';

const RubberStripCuttingMachineSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Rubber Strip Cutting Machine Specifications</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Specification</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cutting Strip Width</td>
                            <td>1 ~ 1000mm</td>
                        </tr>
                        <tr>
                            <td>Cutting Length (600 model)</td>
                            <td>≤580mm</td>
                        </tr>
                        <tr>
                            <td>Cutting Thickness</td>
                            <td>≤15mm</td>
                        </tr>
                        <tr>
                            <td>Voltage</td>
                            <td>380V</td>
                        </tr>
                        <tr>
                            <td>Frequency</td>
                            <td>50Hz</td>
                        </tr>
                        <tr>
                            <td>Cutting Blade Motor Power</td>
                            <td>2.2KW</td>
                        </tr>
                        <tr>
                            <td>Total Machine Weight</td>
                            <td>450KG</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RubberStripCuttingMachineSpec;