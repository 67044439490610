import { v4 as uuidv4 } from 'uuid';

const processItemData = [
    {
        id: uuidv4(),
        number: '01',
        heading: 'Complimentary Technology Training',
        text: 'We offer free inspection and training sessions',
    },

    {
        id: uuidv4(),
        number: '02',
        heading: 'Free Installation and Debugging',
        text: 'Our engineers provide complimentary installation and debugging assistance',
    },

    {
        id: uuidv4(),
        number: '03',
        heading: 'After-Sales Support',
        text: 'We provide free repairs on this machine for one year and offer lifetime maintenance for natural wear and tear',
    },

    {
        id: uuidv4(),
        number: '04',
        heading: 'Remote Software Support',
        text: 'For software issues, we offer online remote control assistance and video troubleshooting via platforms like WhatsApp, WeChat, Teams, and others',
    },
];

export default processItemData;
