import React from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import {
    FaWhatsapp
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const MenuContent = () => (
    <div className="mobile-menu-bottom">
        <ul>
            <li className="bottom-item">
                <a href="mailto:info@juchuanmachines.com">
                    <AiOutlineMail className="icon" />
                </a>
                info@juchuanmachines.com
            </li>
            <li className="bottom-item">
                <a href="https://wa.me/17373281987">
                    <FaWhatsapp />
                </a>
                (+1) 719-JUCHUAN
            </li>
        </ul>
        <div className="bottom-btn">
            <Link to="/contact" className="d-btn theme-btn d-block text-white">
                Contact Us
            </Link>
        </div>
    </div>
);

export default MenuContent;
