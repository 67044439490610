import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

function ProductsTwoCard({ thumbnail, heading, link }) {
    return (
        <div className="col-md-6 col-xl-4 col-12">
            <div className="single-service-card">
                <div className="card-thumb">
                    <img src={thumbnail} alt={heading} loading="lazy"/>
                    <Link to={link} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1
                    }} />
                </div>
                <div className="content">
                    <h3 style={{ textAlign: 'center' }}>
                        <Link to={link}>{heading}</Link>
                    </h3>
                    <Link to={link} className="read-btn" style={{ textAlign: 'center' }}>
                        Learn More <BsArrowRight />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProductsTwoCard;
