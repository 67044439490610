import img1  from '../../../assets/img/machines/hf-brake/brake4cyl.png';
import img2  from '../../../assets/img/machines/hf-brake/fr-demolding.png';

import prod1  from '../../../assets/img/machines/hf-brake/app_products/p1.jpg';
import prod2  from '../../../assets/img/machines/hf-brake/app_products/p2.jpg';
import prod3  from '../../../assets/img/machines/hf-brake/app_products/p3.jpg';
import prod4  from '../../../assets/img/machines/hf-brake/app_products/p4.jpg';
import prod5  from '../../../assets/img/machines/hf-brake/app_products/p5.jpg';
import prod6  from '../../../assets/img/machines/hf-brake/app_products/p6.jpg';
import prod7  from '../../../assets/img/machines/hf-brake/app_products/p7.jpg';
import prod8  from '../../../assets/img/machines/hf-brake/app_products/p8.jpg';
import prod9  from '../../../assets/img/machines/hf-brake/app_products/p9.jpg';
import prod10 from '../../../assets/img/machines/hf-brake/app_products/p10.jpg';

import BrakeHPSpec from '../../../components/MachineSpec/BrakeHPSpec';

const brakeData = {
    thumbImage: img1,
    images: [
        { img: img1 },
        { img: img2 },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
        { img: prod6 },
        { img: prod7 },
        { img: prod8 },
        { img: prod9 },
        { img: prod10 },
    ],
    specTable: <BrakeHPSpec />,
  };
  
  export default brakeData;
  