import React from 'react';
import { ArrowRight } from 'lucide-react';
import ImageCarousel from './ImageCarousel';

const SingleProcess = ({ process, images }) => {
  const fixedHeightWithoutStep = 'auto';

  return (
    <div className="d-flex flex-nowrap overflow-auto py-2 py-md-4">
      {process.stages.map((stage, index) => (
        <React.Fragment key={index}>
          <div style={{ textAlign: 'center', minWidth: '280px', maxWidth: '400px', width: '100%', height: stage.processStep ? 'auto' : fixedHeightWithoutStep }}>
            <div className="bg-white p-2 p-md-3 rounded shadow-sm" style={{ height: fixedHeightWithoutStep }}>
              {images["stages"][stage.name] && (
                <ImageCarousel
                  images={images["stages"][stage.name]}
                  link={stage.link}
                  alt={stage.name}
                  height="150px"
                />
              )}
              <h3 className="font-bold mb-1 mb-md-2 text-center" style={{ fontSize: '1rem' }}>{stage.name}</h3>
              <p style={{ fontSize: '0.875rem' }}>{stage.description}</p>
            </div>
            {stage.processStep && (
              <div className="bg-light p-2 p-md-3 rounded mb-2 mb-md-3 mt-2 mt-md-3">
                {images["products"][stage.name] && (
                  <ImageCarousel
                    images={images["products"][stage.name]}
                    alt={stage.processStep.name}
                    height="100px"
                    noLink={true}
                  />
                )}
                <h5 className="font-bold mb-1 mb-md-2" style={{ fontSize: '0.9rem' }}>{stage.processStep.name}</h5>
                <p style={{ fontSize: '0.8rem' }}>{stage.processStep.description}</p>
              </div>
            )}
          </div>
          {index < process.stages.length - 1 && (
            <div className="d-flex align-items-center mx-1 mx-md-2" style={{ height: '200px' }}>
              <ArrowRight size={20} className="text-primary" />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default SingleProcess;