import React from 'react';
import bannerBg from '../assets/img/juchuan-page-banner.jpeg';
import AboutFeatured from '../components/AboutFeatured';
import Certifications from '../components/Certifications';
import Footer1 from '../components/Footer1';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import Sponsors from '../components/Sponsors';
import Advantages from '../components/Advantages';
import CorporateCulture from '../components/CorporateCulture';
import FactoryGallery from '../components/FactoryGallery';

function About() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} currentPage="About Us" heading="About Us" />
            <AboutFeatured />
            <FactoryGallery />
            <Sponsors />
            <Certifications />
            <Advantages />
            <CorporateCulture />
            <Footer1 />
        </>
    );
}

export default About;
