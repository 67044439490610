import img1  from '../../../assets/img/machines/vi-ao-silicone-injection/viao.png';

import prod1  from '../../../assets/img/machines/vi-ao-silicone-injection/app_products/p1.jpg';
import prod2  from '../../../assets/img/machines/vi-ao-silicone-injection/app_products/p2.jpg';
import prod3  from '../../../assets/img/machines/vi-ao-silicone-injection/app_products/p3.jpg';
import prod4  from '../../../assets/img/machines/vi-ao-silicone-injection/app_products/p4.jpg';
import prod5  from '../../../assets/img/machines/vi-ao-silicone-injection/app_products/p5.jpg';

import VIAOSiliconeSpec from '../../../components/MachineSpec/VIAOSiliconeSpec';

const viaoSiliconeData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Vertical Solid Silicone Injection Molding Machine Image 1' },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
    ],
    specTable: <VIAOSiliconeSpec />
  };
  
  export default viaoSiliconeData;
  