import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { TiMessage } from "react-icons/ti";

import styled from 'styled-components';
import ContactFormContent from '../ContactForm/ContactFormContent';

// STYLES
const ScrollTopWrapper = styled.div`
    position: fixed;
    bottom: 30px;
    right: 30px;
    overflow-x: hidden;
    z-index: 11111;

    button {
        border: none;
        width: 60px;  // Increased from 40px
        height: 60px; // Increased from 40px
        background-color: #9a0001;
        color: #fff;
        border-radius: 50%;
        transition: all 0.3s;
        cursor: pointer;

        @media (max-width: 400px) {
            width: 50px; // Increased from 30px
            height: 50px; // Increased from 30px
        }
    }

    .icon {
        margin-top: -3px;
        font-weight: 300;
        font-size: 24px; // Increased font size

        @media (max-width: 400px) {
            font-size: 20px; // Increased from 12px
        }
    }

    .visible {
        opacity: 1;
        display: block;
        color: #fff;
        transition: opacity 0.3s;
    }

    .invisible {
        opacity: 0;
    }
`;

const PopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11112;
`;

const PopupContent = styled.div`
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    position: relative;
    width: 90vw;
    max-width: 800px;
    height: 80vh;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 95vw;
        height: 90vh;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
`;


function FlowPopup() {
    const [isVisible, setIsVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    // Handler
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <>
            <ScrollTopWrapper>
                <button
                    className={isVisible ? 'd-block' : 'd-none'}
                    type="button"
                    onClick={openPopup}
                >
                    <TiMessage className="icon" />
                </button>
            </ScrollTopWrapper>
            {showPopup && (
                <PopupWrapper>
                    <PopupContent>
                        <CloseButton onClick={closePopup}>
                            <FaTimes />
                        </CloseButton>
                        <ContactFormContent title="Contact Us" heading="Leave Us A Message" />
                    </PopupContent>
                </PopupWrapper>
            )}
        </>
    );
}

export default FlowPopup;