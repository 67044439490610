/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import Select from 'react-select';
import DetailFormInput from './DetailFormInput.jsx';
import countryOptions from '../SharedFormData/CountriesOptions';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

function DetailsForm({ machineSelect }) {
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        company: '',
        fullname: '',
        application: '',
        email: '',
        message: '',
        machine: machineSelect,
        country: null,
    });

    const onSelectChange = (selectedOption, { name }) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: selectedOption
        }));
    };

    const onChangeHandler = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };    

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        const url = 'https://form-automation.juchuanofficial.workers.dev';
    
        const requestBody = {
            company: formData.company,
            name: formData.fullname,
            email: formData.email,
            application: formData.application,
            message: formData.message,
            machine: formData.machine,
            country: formData.country.label
        };

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            if (response.ok) {
                setSubmissionStatus('success');
            } else {
                setSubmissionStatus('error');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmissionStatus('error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className="cotact-form-wrappner pt-0">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2>Get a Quote</h2>
                        {submissionStatus === 'error' && (
                            <p style={{ marginTop: '15px', color: 'red' }}>Error submitting form: <br/> We apologize for the inconvenience. We're currently experiencing technical difficulties. Please email us at: info@juchuanmachines.com or try again later.</p>
                        )}
                    </div>

                    {submissionStatus === 'success' ? (
                        <div className="col-12 text-center">
                            <h3>Thank you for your submission <MarkEmailReadIcon /></h3>
                            <p>We have received your form and will get back to you soon!</p>
                        </div>
                    ) : (
                    <div className="col-12 col-lg-12">
                        <div className="detail-contact-form">
                            <form onSubmit={onSubmitHandler} className="row detail-contact-form">
                                <DetailFormInput
                                    type="text"
                                    labelFor="company"
                                    label="Company Name"
                                    placeholder="Enter Company Name"
                                    id="company"
                                    value={formData.company}
                                    onChange={onChangeHandler}
                                    autoComplete="company"
                                    required
                                />
                                <DetailFormInput
                                    type="text"
                                    labelFor="fullname"
                                    label="Fullname"
                                    placeholder="Enter Name"
                                    id="fullname"
                                    value={formData.fullname}
                                    onChange={onChangeHandler}
                                    autoComplete="name"
                                    required
                                />
                                <DetailFormInput
                                    type="email"
                                    labelFor="email"
                                    label="Email Address"
                                    placeholder="Enter Email Address"
                                    id="email"
                                    value={formData.email}
                                    onChange={onChangeHandler}
                                    autoComplete="email"
                                    required
                                />
                                <DetailFormInput
                                    type="text"
                                    labelFor="application"
                                    label="Application product"
                                    placeholder="example: rubber gloves etc"
                                    id="application"
                                    value={formData.application}
                                    onChange={onChangeHandler}
                                    autoComplete="off"
                                    required
                                />
                                <div className="single-personal-info">
                                    <label htmlFor="country-select">Country</label>
                                    <Select
                                        inputId="country-select"
                                        name="country"
                                        options={countryOptions}
                                        value={formData.country || null}
                                        onChange={(selectedOption, actionMeta) => onSelectChange(selectedOption, actionMeta)}
                                        placeholder="Select a country"
                                        required
                                    />
                                </div>
                                <div className="col-md-12 col-12">
                                    <div className="single-personal-info">
                                        <label htmlFor="message">Enter Message</label>
                                        <textarea
                                            value={formData.message}
                                            onChange={onChangeHandler}
                                            id="message"
                                            placeholder="Describe your product for our rubber/silicone molding machine. Include specs and preferred contact method."
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 col-12 text-center">
                                    <input
                                        className="submit-btn"
                                        type="submit"
                                        value={isLoading ? "Submitting..." : "Get A Quote"}
                                        disabled={isLoading}
                                        style={{
                                            opacity: isLoading ? 0.5 : 1,
                                            cursor: isLoading ? 'not-allowed' : 'pointer'
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default DetailsForm;
