import { v4 as uuidv4 } from 'uuid';

import press3000Thumb from '../../assets/img/machines/custom/1-3000TPressMachine.png';
import press300Thumb from '../../assets/img/machines/custom/300THotPressMachine.png';
import vacuum300Thumb from '../../assets/img/machines/custom/300TVacuumCompressionMoldingMachine.png';
import press400Thumb from '../../assets/img/machines/custom/400THotPressMachine.png';
import topCylinder400Thumb from '../../assets/img/machines/custom/400TTopCylinderPressMachine.png';
import press500Thumb from '../../assets/img/machines/custom/500THotPressMachine.png';
import vacuum300LongStrokeThumb from '../../assets/img/machines/custom/500mm-stroke300TVacuumCompressionMoldingMachine.png';
import press600Thumb from '../../assets/img/machines/custom/600THotPressMachine.png';
import vacuum600Thumb from '../../assets/img/machines/custom/600TVacuumCompressionMoldingMachine.png';
import brakeShoesThumb from '../../assets/img/machines/custom/BrakeShoesPressMachine.png';
import frontRearDemoldingThumb from '../../assets/img/machines/custom/FrontRearDemoldingVacuumCompressionMoldingMachine.png';
import horizontalCompressionThumb from '../../assets/img/machines/custom/HorizontalCompressionMoldingMachine.png';

const CustomGalleryData = [
    {
        id: uuidv4(),
        thumbnail: topCylinder400Thumb,
        heading: '400T Top Cylinder Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: press3000Thumb,
        heading: '1000-3000T Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: frontRearDemoldingThumb,
        heading: 'Front & Rear Demolding Vacuum Compression Molding Machine',
    },
    {
        id: uuidv4(),
        thumbnail: vacuum300Thumb,
        heading: '600x1000mm 300T Vacuum Compression Molding Machine',
    },
    {
        id: uuidv4(),
        thumbnail: press400Thumb,
        heading: '700x1500mm 400T Hot Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: horizontalCompressionThumb,
        heading: 'Horizontal Compression Molding Machine',
    },
    {
        id: uuidv4(),
        thumbnail: vacuum300LongStrokeThumb,
        heading: '500mm-Stroke 300T Vacuum Compression Molding Machine',
    },
    {
        id: uuidv4(),
        thumbnail: press500Thumb,
        heading: '1000x1000mm 500T Hot Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: press300Thumb,
        heading: '700x1100mm 300T Hot Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: brakeShoesThumb,
        heading: 'Brake Shoes Press Machine',
    },
    {
        id: uuidv4(),
        thumbnail: vacuum600Thumb,
        heading: '600T Vacuum Compression Molding Machine',
    },
    {
        id: uuidv4(),
        thumbnail: press600Thumb,
        heading: '600T Hot Press Machine',
    },
];

export default CustomGalleryData;
