import React from 'react';

function KeyFeatures({keyFeatures}) {
    const BulletPoint = ({ children }) => (
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0.5em' }}>
          <div style={{ marginRight: '0.5em', fontSize: '1.2em', marginTop: '0.1em' }}>•</div>
          <div>{children}</div>
        </div>
    );

    return (
        <section className="section-bg" style={{ padding: '25px', marginTop: '20px'}}>
            <>
                <h2 style={{ textAlign: 'center'}}>Key Features</h2>
                {keyFeatures.map((feature, index) => (
                    <BulletPoint key={index}>{feature}</BulletPoint>
                ))}
            </>
        </section>
    );
}

export default KeyFeatures;
