import img1  from '../../../assets/img/machines/Antistatic Washing and Drying Machine/Antistatic Washing and Drying Machine 40KG.png';
import img2  from '../../../assets/img/machines/Antistatic Washing and Drying Machine/Antistatic Washing and Drying Machine 20KG.png';

import prod1  from '../../../assets/img/machines/Antistatic Washing and Drying Machine/app_products/antistatic-washed-smartphone-cases.png';
import prod2  from '../../../assets/img/machines/Antistatic Washing and Drying Machine/app_products/electronic-controls-antistatic-cleaned.jpg';
import prod3  from '../../../assets/img/machines/Antistatic Washing and Drying Machine/app_products/rubber-plastic-parts-antistatic-washing.png';

import AntistaticWashingDryingMachineSpec from '../../../components/MachineSpec/AntistaticWashingDryingMachineSpec';

const washingDryingData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'Antistatic Washing and Drying Machine 40KG' },
        { img: img2, alt: 'Antistatic Washing and Drying Machine 20KG' }
    ],
    applicationImages: [
        { img: prod1, alt: 'Colorful smartphone cases cleaned with antistatic washing and drying machine' },
        { img: prod2, alt: 'Electronic device control panels sanitized by antistatic washing and drying' },
        { img: prod3, alt: 'Assorted rubber and plastic components for antistatic cleaning in industrial washer-dryer' },
    ],
    specTable: <AntistaticWashingDryingMachineSpec />
  };
  
  export default washingDryingData;
