const machineOptions = [
    { value: 'general', label: 'General Inquries' },
    { value: 'customized', label: 'Customized Machine' },
    { value: 'brakehp', label: 'Brake Pad and Lining Hot Press Machine' },
    { value: 'hf', label: 'Hydraulic Vulcanizing Hot Press Machine' },
    { value: 'hiflSilicone', label: 'HI-FL Horizontal Silicone Injection Molding Machine' },
    { value: 'hiflRubber', label: 'HI-FL Horizontal Rubber Injection Molding Machine' },
    { value: 'lv1', label: 'LV-1 Vertical Liquid Silicone Injection Molding Machine' },
    { value: 'lv2', label: 'LV-2 Solid/Liquid Silicone Molding Machine' },
    { value: 'tppress', label: 'Vertical Transfer Press Molding Machine' },
    { value: 'vf', label: 'Vacuum Compression Molding Machine' },
    { value: 'viaorubber', label: 'VI-AO Vertical Rubber Injection Molding Machine' },
    { value: 'viaosilicone', label: 'VI-AO Vertical Solid Silicone Injection Molding Machine' },
    { value: 'vifl', label: 'VI-FL Vertical Rubber Injection Molding Machine' },
    { value: 'vifo', label: 'VI-FO Vertical Rubber Injection Molding Machine' },
    { value: 'mill', label: 'Rubber Two-Roll Mill Machine' },
    { value: 'washdry', label: 'Antistatic Washing and Drying Machine' },
    { value: 'hotDry', label: 'Stainless Steel Hot Air Rotary Drum Dryer' },
    { value: 'deflashing', label: 'Rubber Deflashing Machine' },
    { value: 'siliconeCutting', label: 'Silicone Strip Cutting Machine' },
    { value: 'rubberCutting', label: 'Rubber Strip Cutting Machine' },
    { value: 'openMill', label: 'Two-Roll Open Mill Machine for Rubber Mixing' },
    { value: 'feederMill', label: 'Air Separation Machine with Automatic Feeder' },
    { value: 'punching', label: 'Punching Machine' },
];

export default machineOptions;
