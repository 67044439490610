import React from 'react';

const SiliconeStripCuttingMachineSpec = () => {
    return (
        <div className="specifications-table">
            <h2>Silicone Strip Cutting Machine Specifications</h2>
            <div style={{ overflowX: 'auto' }}>
                <table>
                    <thead>
                        <tr>
                            <th>Specification</th>
                            <th>Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cutting Capacity</td>
                            <td>
                                Width: ≤520mm<br />
                                Length: 0.1~500mm<br />
                                Thickness: ≤12mm (for silicone rubber)
                            </td>
                        </tr>
                        <tr>
                            <td>Customization</td>
                            <td>Special cases can be designed and manufactured according to customer requirements</td>
                        </tr>
                        <tr>
                            <td>Power Supply</td>
                            <td>AC220V, 50Hz</td>
                        </tr>
                        <tr>
                            <td>Cutting Blade Motor Power</td>
                            <td>120W</td>
                        </tr>
                        <tr>
                            <td>Cutting Blade Speed</td>
                            <td>Adjustable via touchscreen, both length and speed can be fine-tuned</td>
                        </tr>
                        <tr>
                            <td>Cutting Capability</td>
                            <td>Can simultaneously cut four different widths of rubber material</td>
                        </tr>
                        <tr>
                            <td>Customizable Settings</td>
                            <td>Number of strips per section and total number of strips can be set, meeting special customer needs</td>
                        </tr>
                        <tr>
                            <td>Machine Dimensions (L x W x H)</td>
                            <td>1200mm x 850mm x 1220mm</td>
                        </tr>
                        <tr>
                            <td>Net Weight</td>
                            <td>200kg</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SiliconeStripCuttingMachineSpec;