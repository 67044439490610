import React from 'react';
import DetailsCarousel from './DetailsCarousel';
import DetailsForm from './DetailsForm';
import KeyFeatures from './KeyFeatures';
import SingleLineImageList from './SingleLineImageList';

function EachProductDetails({ jsonData, jsData }) {
    const { images, applicationImages, specTable } = jsData;

    return (
        <div className="product-details-content-wrapper pl-0 pl-md-4">
            <h1 style={{ textAlign: 'center', paddingBottom: '50px', color: '#001659' }}>{jsonData.title}</h1>

            <div className="row">
                <div className="col-md-8 d-flex align-items-center justify-content-center">
                    <div className="w-100">
                        <DetailsCarousel images={images} title={jsonData.title} />
                    </div>
                </div>
                <div className="col-md-4">
                    <DetailsForm machineSelect={jsonData.title} />
                </div>
            </div>

            <KeyFeatures keyFeatures={jsonData.keyFeatures} />

            {applicationImages && (
                <SingleLineImageList itemData={applicationImages} />
            )}

            {specTable && (
                <div className="row" style={{ paddingTop: '30px' }}>
                    {specTable}
                </div>
            )}
        </div>
    );
}

export default EachProductDetails;
