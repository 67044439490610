import img1  from '../../../assets/img/machines/lv-2-silicone-molding/lv2.png';

import prod1 from '../../../assets/img/machines/lv-2-silicone-molding/app_products/p1.jpg';
import prod2 from '../../../assets/img/machines/lv-2-silicone-molding/app_products/p2.jpg';
import prod3 from '../../../assets/img/machines/lv-2-silicone-molding/app_products/p3.jpg';
import prod4 from '../../../assets/img/machines/lv-2-silicone-molding/app_products/p4.jpg';
import prod5 from '../../../assets/img/machines/lv-2-silicone-molding/app_products/p5.jpg';

import LV2Spec from '../../../components/MachineSpec/LV2Spec';

const lv2Data = {
    thumbImage: img1,
    images: [
        { img: img1 },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
    ],
    specTable: <LV2Spec />,
  };
  
  export default lv2Data;
  