import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/project/tech1.png';
import Img2 from '../../assets/img/project/tech2.png';
import Img3 from '../../assets/img/project/tech3.jpg';
import Img4 from '../../assets/img/project/tech4.png';

const techOneData = [
    {
        id: uuidv4(),
        image: Img1,
        heading: 'One-piece Molded Cylinder Design',
        desc: 'Eliminates the need for flange fastening screws, enhancing structural integrity in our silicone and rubber molding machines.',
        other: 'Requires screws to secure the cylinder flange, potentially compromising structural integrity and introducing additional maintenance points.',
        keywords: ['Eliminates the need for flange fastening screws', 'structural integrity'],
    },

    {
        id: uuidv4(),
        image: Img2,
        heading: 'Precision Tolerance',
        desc: 'Achieves tight tolerances of 0.08-0.1mm, ensuring superior component fit and performance in molding operations.',
        other: 'Gap between piston and cylinder approximately 0.5mm, reducing precision and potentially affecting molding quality.',
        keywords: ['Achieves tight tolerances'],
    },

    {
        id: uuidv4(),
        image: Img3,
        heading: 'Enhanced Stability and Alignment',
        desc: 'Reduced tolerances result in improved machine stability and superior coaxiality between the main cylinder and piston, critical for consistent molding results.',
        other: 'Larger tolerances lead to piston movement fluctuations, resulting in accelerated seal wear and potential inconsistencies in molded products.',
        keywords: ['improved machine stability and superior coaxiality', 'consistent molding results'],
    },

    {
        id: uuidv4(),
        image: Img4,
        heading: 'Extended Seal Longevity and Easy Maintenance',
        desc: 'Main cylinder seals last over 5 years, with quick and simple replacement procedures, minimizing downtime in production.',
        other: 'Cylinder seals require more frequent changes, with longer downtime for maintenance procedures, impacting overall production efficiency.',
        keywords: ['minimizing downtime in production'],
    },
];

export default techOneData;
