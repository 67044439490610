import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const ImageCarousel = ({ images, link, alt, height, noLink = false }) => {
  const links = Array.isArray(link) ? link : [link];
  
  const ImageContent = ({ img, index }) => (
    <img
      src={img}
      alt={`${alt} ${index + 1}`}
      style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
      loading="lazy"
    />
  );

  return (
    <div className="mb-2 mb-md-3" style={{ height: height }}>
      {images.length > 1 ? (
        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          style={{ height: '100%' }}
        >
          {images.map((img, index) => (
            <SwiperSlide key={index} style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {noLink ? (
                <ImageContent img={img} index={index} />
              ) : (
                <Link to={links[index] || links[0]} className="d-block h-100 w-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ImageContent img={img} index={index} />
                </Link>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        noLink ? (
          <div className="d-block h-100 w-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ImageContent img={images[0]} index={0} />
          </div>
        ) : (
          <Link to={links[0]} className="d-block h-100 w-100" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ImageContent img={images[0]} index={0} />
          </Link>
        )
      )}
    </div>
  );
};

export default ImageCarousel;