import React from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import logo from '../../assets/img/logo.png';
import WidgetAbout from './WidgetAbout';
import WidgetGetinTouch from './WidgetGetinTouch';
import WidgetGetinTouchData from './WidgetGetinTouchData';

function Footer1() {
    return (
        <footer className="footer-1 footer-wrap">
            <div className="footer-widgets dark-bg">
                <div className="container">
                    <div className="row">
                        {/* ABOUT WIDGET */}
                        <WidgetAbout
                            text="Guangzhou Juchuan Machinery Co., Ltd. manufactures rubber and silicone equipment. Established in 1997,
                            with a large facility and advanced equipment. Product range includes silicone molding machines, rubber molding machines,
                            and custom molds. Expertise in R&D, manufacturing, and service. Trust our experience for your equipment needs."
                            ttLink="https://www.tiktok.com/@juchuanmachines"
                            whatsappLink="https://wa.me/17373281987"
                            youtubeLink="https://www.youtube.com/@JuchuanManchinery"
                        />

                        <div className="col-md-6 col-xl-6 col-12">
                            <div className="single-footer-wid ml-15 site_info_widget">
                                <div className="wid-title">
                                    <h4>Get In Touch</h4>
                                </div>
                                <div className="contact-us" />
                                {WidgetGetinTouchData.map((data) => (
                                    <WidgetGetinTouch
                                        key={data.id}
                                        icon={data.icon}
                                        heading={data.heading}
                                        text={data.text}
                                        refLink={data.refLink}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer bottom */}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-12">
                            <div className="copyright-info">
                                <p>
                                    &copy; Copyright By <a href="/">Juchuan Machinery</a> - 2024
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 text-center">
                            <div className="footer-logo">
                                <a href="#top">
                                    <img src={logo} alt="Juchuan" loading="lazy"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 d-none d-lg-block col-12">
                            <div className="scroll-up-btn text-md-right justify-content-end">
                                <a href="#top">
                                    <AiOutlineArrowUp />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer1;
