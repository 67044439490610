import img1  from '../../../assets/img/machines/vi-fo-rubber-injection/vifo-white.png';

import prod1  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p1.jpg';
import prod2  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p2.jpg';
import prod3  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p3.jpg';
import prod4  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p4.jpg';
import prod5  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p5.jpg';
import prod6  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p6.jpg';
import prod7  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p7.jpg';
import prod8  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p8.jpg';
import prod9  from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p9.jpg';
import prod10 from '../../../assets/img/machines/vi-fo-rubber-injection/app_products/p10.jpg';

import VIFOSpec from '../../../components/MachineSpec/VIFOSpec';

const vifoData = {
    thumbImage: img1,
    images: [
        { img: img1, alt: 'VI-FO Vertical Rubber Injection Molding Machine Image 1' },
    ],
    applicationImages: [
        { img: prod1 },
        { img: prod2 },
        { img: prod3 },
        { img: prod4 },
        { img: prod5 },
        { img: prod6 },
        { img: prod7 },
        { img: prod8 },
        { img: prod9 },
        { img: prod10 },
    ],
    specTable: <VIFOSpec />
  };
  
  export default vifoData;
  